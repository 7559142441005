import { combineReducers, compose } from "redux";
import { AsyncAction, createReducer, foldP } from "re-reduced";

import uniq from "ramda/src/uniq";
import always from "ramda/src/always";
import concat from "ramda/src/concat";
import mergeLeft from "ramda/src/mergeLeft";
import indexBy from "ramda/src/indexBy";

import {
  AsyncCollection,
  Result,
  RequestState,
  REQUEST_STATUS,
} from "lib/types";

export const REQUEST_INITIAL_STATE: RequestState<any> = {
  status: REQUEST_STATUS.Idle,
};

export function createRequestReducer<
  TResult = any,
  TPayload = void,
  TError = Error
>(action: AsyncAction<TResult, TPayload, TError>) {
  const INITIAL_STATE: RequestState<TError> = REQUEST_INITIAL_STATE;

  return createReducer<RequestState<TError>>(
    [
      action.request.reduce(
        always<RequestState<TError>>({
          status: REQUEST_STATUS.Pending,
        })
      ),
      action.success.reduce(
        always<RequestState<TError>>({
          status: REQUEST_STATUS.Fulfilled,
          lastExecuted: new Date(),
        })
      ),
      action.failure.reduce((_, error) => ({
        status: REQUEST_STATUS.Failed,
        error,
      })),
    ],
    INITIAL_STATE
  );
}

export const ASYNC_COLLECTION_INITIAL_STATE: AsyncCollection<any, any> = {
  byId: {},
  idList: [],
  request: REQUEST_INITIAL_STATE,
};

export function createAsyncCollectionReducer<
  TData,
  TPayload = void,
  TError = Error,
  TResult extends Result<TData[]> = Result<TData[]>
>(
  action: AsyncAction<TResult, TPayload, TError>,
  idKey: keyof TData,
  initialState?: Partial<AsyncCollection<TData, TError>>
) {
  type TState = AsyncCollection<TData, TError>;

  const defaultState: TState = ASYNC_COLLECTION_INITIAL_STATE;

  const INITIAL_STATE: TState = initialState
    ? mergeLeft(defaultState, initialState)
    : defaultState;

  return combineReducers<AsyncCollection<TData, TError>>({
    byId: createReducer(
      foldP(action.success, (payload) =>
        mergeLeft(indexBy((item) => String(item[idKey]), payload.items))
      ),
      INITIAL_STATE.byId
    ),
    idList: createReducer(
      foldP(action.success, (payload) =>
        compose(uniq, concat(payload.items.map((item) => String(item[idKey]))))
      ),
      INITIAL_STATE.idList
    ),
    request: createRequestReducer(action),
  });
}
