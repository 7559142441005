import React, { useState } from "react";

import StyledSwitch from "ui/components/StyledSwitch";

import {
  Container,
  SectionContainer,
  SectionHeaderContainer,
  SectionHeader,
  OptionContainer,
  LoadingOverlay,
  handleToggleChange,
  isProductDisabled,
  ActionPendingConfirmation,
  ConfirmChangeStockAvailabilityModal,
  SectionList,
} from "./Shared";
import actions from "domain/core/app/actions";
import {
  getDisabledItems,
  getDisabledItemsRequestState,
  getVeganEnabledRemoteConfig,
} from "domain/selectors";
import { useActions, useReduxState } from "re-reduced";
import { REQUEST_STATUS } from "lib/types";
import { StockManagerItem } from "domain/core/app/types";
import {
  ChilledDrinksItems,
  FrappeDrinkItems,
  HotDrinkItems,
  IcedDrinkItems,
} from "domain/core/app/stockConstants";

const unboundActions = {
  updateProductAvailability: actions.updateProductAvailability,
};

const stateSelectorMap = {
  disabledItems: getDisabledItems,
  disabledItemsRequestState: getDisabledItemsRequestState,
  veganSmoothieEnabled: getVeganEnabledRemoteConfig,
};

export default function ChilledDrinks() {
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectorMap);

  const isLoading = state.disabledItemsRequestState === REQUEST_STATUS.Pending;

  const [actionPendingConfirmation, setActionPendingConfirmation] = useState<
    ActionPendingConfirmation
  >();

  const handleConfirmDialogClose = () => {
    setActionPendingConfirmation(undefined);
  };

  const handleConfirmDialogConfirm = () => {
    if (actionPendingConfirmation) {
      actions.updateProductAvailability({
        productIds: [actionPendingConfirmation.item.productId],
        disableOrEnable: actionPendingConfirmation.disableOrEnable,
      });

      handleConfirmDialogClose();
    }
  };

  const Items = ChilledDrinksItems(
    state.veganSmoothieEnabled
  ) as StockManagerItem[];

  return (
    <Container>
      <LoadingOverlay isLoading={isLoading} />
      <SectionContainer>
        <SectionHeaderContainer>
          <SectionHeader>Smoothies</SectionHeader>
        </SectionHeaderContainer>
        <SectionList>
          {Items.map((item) => (
            <OptionContainer
              key={item.productId}
              control={
                <StyledSwitch
                  onChange={handleToggleChange(
                    item,
                    isLoading,
                    setActionPendingConfirmation
                  )}
                  checked={
                    !isProductDisabled(item.productId, state.disabledItems)
                  }
                />
              }
              label={item.label}
            />
          ))}
        </SectionList>
      </SectionContainer>

      <SectionContainer>
        <SectionHeaderContainer>
          <SectionHeader>Frappes</SectionHeader>
        </SectionHeaderContainer>
        <SectionList>
          {FrappeDrinkItems.map((item) => (
            <OptionContainer
              key={item.productId}
              control={
                <StyledSwitch
                  onChange={handleToggleChange(
                    item,
                    isLoading,
                    setActionPendingConfirmation
                  )}
                  checked={
                    !isProductDisabled(item.productId, state.disabledItems)
                  }
                />
              }
              label={item.label}
            />
          ))}
        </SectionList>
      </SectionContainer>

      <SectionContainer>
        <SectionHeaderContainer>
          <SectionHeader>Hot drinks</SectionHeader>
        </SectionHeaderContainer>
        <SectionList>
          {HotDrinkItems.map((item) => (
            <OptionContainer
              key={item.productId}
              control={
                <StyledSwitch
                  onChange={handleToggleChange(
                    item,
                    isLoading,
                    setActionPendingConfirmation
                  )}
                  checked={
                    !isProductDisabled(item.productId, state.disabledItems)
                  }
                />
              }
              label={item.label}
            />
          ))}
        </SectionList>
      </SectionContainer>
      <SectionContainer>
        <SectionHeaderContainer>
          <SectionHeader>Iced drinks</SectionHeader>
        </SectionHeaderContainer>
        <SectionList>
          {IcedDrinkItems.map((item) => (
            <OptionContainer
              key={item.productId}
              control={
                <StyledSwitch
                  onChange={handleToggleChange(
                    item,
                    isLoading,
                    setActionPendingConfirmation
                  )}
                  checked={
                    !isProductDisabled(item.productId, state.disabledItems)
                  }
                />
              }
              label={item.label}
            />
          ))}
        </SectionList>
      </SectionContainer>

      <ConfirmChangeStockAvailabilityModal
        actionPendingConfirmation={actionPendingConfirmation}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDialogConfirm}
      />
    </Container>
  );
}
