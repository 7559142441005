import { withStyles, Switch } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";

const StyledSwitch = withStyles({
  switchBase: {
    color: red[500],
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export default StyledSwitch;
