import React from "react";
import styled from "styled-components";

import CoffeeIllustrationSrc from "assets/coffee-takeaway.png";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.54);
  text-align: center;
  height: 100%;
`;

const Image = styled.img`
  filter: grayscale(100%);
  height: 160px;
  width: 160px;
`;

const EmptyState: React.FC = (props) => (
  <Wrapper>
    <Image
      src={CoffeeIllustrationSrc}
      alt="Coffee Takeaway. Icon made by Monkik from www.flaticon.com"
    />
    {props.children}
  </Wrapper>
);

EmptyState.defaultProps = {
  children: "Nothing to see here",
};

EmptyState.displayName = "EmptyState";

export default EmptyState;
