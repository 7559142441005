import React, { Component } from "react";
import { connectWithActions } from "re-reduced";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ReplayIcon from "@material-ui/icons/Replay";
import orange from "@material-ui/core/colors/orange";
import { createStyles, withStyles, WithStyles } from "@material-ui/styles";

import unboundActions from "domain/core/app/actions";

const styles = createStyles({
  wrapper: {
    background: orange[100],
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    margin: 20,
  },
  button: {
    padding: 8,
    width: 200,
  },
  buttonIcon: {
    marginRight: 4,
  },
});

interface Props extends WithStyles<typeof styles> {
  actions: typeof unboundActions;
}

interface State {
  hasError: boolean;
  isLoading: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public static displayName = "ErrorBoundary";

  public state = { hasError: false, isLoading: false };

  private timeoutId = 0;

  public static getDerivedStateFromError() {
    return { hasError: true, isLoading: false };
  }

  public componentDidCatch<T>(error: T, info: any) {
    this.props.actions.logEvent({
      kind: "Error",
      message: "An unhandled UI exception was thrown",
      error,
      info,
    });
  }

  public componentWillUnmount() {
    window.clearTimeout(this.timeoutId);
  }

  private handleReset = () => {
    this.props.actions.reset();
    this.setState({ hasError: false, isLoading: true });
    this.timeoutId = window.setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  public render() {
    if (this.state.hasError) {
      return (
        <Container className={this.props.classes.wrapper}>
          <Typography
            variant="h3"
            color="primary"
          >{`Something didn't work as expected`}</Typography>
          <div className={this.props.classes.buttonWrapper}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.handleReset}
              className={this.props.classes.button}
              disabled={this.state.isLoading}
            >
              {this.state.isLoading ? (
                "Resetting state…"
              ) : (
                <>
                  <ReplayIcon className={this.props.classes.buttonIcon} /> Reset
                  Barista
                </>
              )}
            </Button>
          </div>
        </Container>
      );
    }

    return this.props.children;
  }
}

const enhance = connectWithActions<Props>(unboundActions);

export default enhance(withStyles(styles)(ErrorBoundary));
