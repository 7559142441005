import React, { useState, useCallback, ReactElement } from "react";
import { useActions, useReduxState } from "re-reduced";
import { useHistory, useLocation } from "react-router-dom";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ExitIcon from "@material-ui/icons/ExitToApp";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckForUpdatesIcon from "@material-ui/icons/Autorenew";
import UpdateIcon from "@material-ui/icons/Update";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import ReceiptIcon from "@material-ui/icons/Receipt";

import { APP_NAME } from "domain/core/app/configuration";
import { getAppVersion, getHasUpdates } from "domain/core/selectors";
import unboundActions from "domain/core/actions";
import ConfirmDialog from "ui/components/ConfirmDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      minWidth: 200,
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
    subtitle: {
      marginLeft: 8,
    },
    iconWrapper: {
      minWidth: theme.spacing(5),
    },
  })
);

interface MenuItemProps {
  icon: ReactElement;
  onClick: () => void;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const classes = useStyles();

  return (
    <ListItem button onClick={props.onClick}>
      <ListItemIcon className={classes.iconWrapper}>{props.icon}</ListItemIcon>
      {props.children}
    </ListItem>
  );
};

interface Props {
  open?: boolean;
  siteName: string | null;
  onClose(): void;
}

const stateSelectors = {
  appVersion: getAppVersion,
  hasUpdates: getHasUpdates,
};

const LeftMenu: React.FC<Props> = (props) => {
  const classes = useStyles();
  const actions = useActions(unboundActions);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const state = useReduxState(stateSelectors);
  const history = useHistory();
  const location = useLocation();

  const handleShowLogoutDialog = useCallback(
    () => setShowLogoutDialog(true),
    []
  );

  const handleCloseLogoutDialog = useCallback(
    () => setShowLogoutDialog(false),
    []
  );

  const handleConfirmLogout = useCallback(() => actions.auth.logout(), [
    actions.auth,
  ]);

  const handleCheckForUpdates = useCallback(
    () => actions.app.checkForUpdates(),
    [actions.app]
  );

  const handleInstallUpdate = useCallback(() => actions.app.installUpdate(), [
    actions.app,
  ]);

  const handleStockManagerClick = () => {
    history.push("/stockmanager");
    props.onClose();
  };

  const handleOrdersClick = () => {
    history.push("/");
    props.onClose();
  };

  const isOnStockManager = location.pathname.includes("/stockmanager");

  return (
    <>
      <Drawer anchor="left" open={props.open} onClose={props.onClose}>
        <List className={classes.list}>
          <ListItem>
            <Typography variant="h6" className={classes.title}>
              {APP_NAME}
              <Typography component="span" className={classes.subtitle}>
                | {props.siteName}
              </Typography>
            </Typography>
          </ListItem>
          <Divider />
          {isOnStockManager ? (
            <MenuItem icon={<ReceiptIcon />} onClick={handleOrdersClick}>
              <Typography>{"Orders"}</Typography>
            </MenuItem>
          ) : (
            <MenuItem
              icon={<RemoveShoppingCartIcon />}
              onClick={handleStockManagerClick}
            >
              <Typography>{"Stock manager"}</Typography>
            </MenuItem>
          )}

          <Divider />

          {state.hasUpdates ? (
            <MenuItem
              icon={<UpdateIcon color="secondary" />}
              onClick={handleInstallUpdate}
            >
              <Typography color="secondary">{"Install Update"}</Typography>
            </MenuItem>
          ) : (
            <MenuItem
              icon={<CheckForUpdatesIcon />}
              onClick={handleCheckForUpdates}
            >
              <Typography>{"Check for Updates…"}</Typography>
            </MenuItem>
          )}

          <MenuItem icon={<RefreshIcon />} onClick={handleInstallUpdate}>
            <Typography>{"Restart Barista"}</Typography>
          </MenuItem>

          <Divider />

          <MenuItem icon={<ExitIcon />} onClick={handleShowLogoutDialog}>
            <Typography>{"Logout"}</Typography>
          </MenuItem>
        </List>
        <Divider />
        <List>
          <ListItem button>
            {APP_NAME} v{state.appVersion}
          </ListItem>
        </List>
      </Drawer>
      <ConfirmDialog
        open={showLogoutDialog}
        onClose={handleCloseLogoutDialog}
        onCancel={handleCloseLogoutDialog}
        onConfirm={handleConfirmLogout}
        title="Are you sure you want to logout?"
        cancelLabel="Cancel"
        confirmLabel="Logout"
      />
    </>
  );
};

LeftMenu.displayName = "LeftMenu";

export default LeftMenu;
