import { ProductId } from "../types";
import { StockManagerItem } from "./types";

export const MuffinItems: StockManagerItem[] = [
  {
    label: "Blueberry Muffins",
    productId: "muffin-blueberry",
  },
  {
    label: "Double Choc Muffins",
    productId: "muffin-double-choc",
  },
  {
    label: "Raspberry White Choc & Apple Muffin",
    productId: "muffin-raspberry-white-choc-apple",
  },
  {
    label: "Carrot Cake Muffins",
    productId: "muffin-carrot-cake",
  },
];

export const MuffinProductIds: ProductId[] = MuffinItems.map(
  (item) => item.productId
);

export const HotFoodItems: StockManagerItem[] = [
  {
    label: "Mince & Cheese Pie",
    productId: "pie-mince-cheese",
  },
  {
    label: "Steak & Cheese Pie",
    productId: "pie-steak-cheese",
  },
];

export const HotFoodProductIds: ProductId[] = HotFoodItems.map(
  (item) => item.productId
);

export const ChilledDrinksItems = (veganEnabled: boolean) =>
  veganEnabled
    ? [
        {
          label: "Banana Berry Smoothie",
          productId: "smoothie-berry-breakfast",
        },
        {
          label: "Tropical Smoothie",
          productId: "vegan-smoothie-tropical",
        },
      ]
    : [
        {
          label: "Banana Berry Smoothie",
          productId: "smoothie-berry-breakfast",
        },
      ];

export const FrappeDrinkItems: StockManagerItem[] = [
  {
    label: "Donut Frappé",
    productId: "frappe-donut",
  },
  {
    label: "Easter Egg Frappé",
    productId: "frappe-easter-egg",
  },
  {
    label: "Hot Cross Bun Frappé",
    productId: "frappe-hot-cross-bun",
  },
  {
    label: "Banoffee Frappé",
    productId: "frappe-banoffee",
  },
  {
    label: "Bubblegum Frappé",
    productId: "frappe-bubblegum",
  },
  {
    label: "Black Forest Frappé",
    productId: "frappe-black-forest",
  },
  {
    label: "Triple Chocolate Frappé",
    productId: "frappe-triple-chocolate",
  },
  {
    label: "Smores Frappé",
    productId: "frappe-smores",
  },
  {
    label: "Pina Colada Frappé",
    productId: "frappe-pina-colada",
  },
  {
    label: "Raspberry White Choc Frappé",
    productId: "frappe-raspberry-white-choc",
  },
  {
    label: "Candy Cane Frappé",
    productId: "frappe-candy-cane",
  },
  {
    label: "Matcha Frappé",
    productId: "frappe-matcha",
  },
  {
    label: "Hokey Pokey Frappé",
    productId: "frappe-hokey-pokey",
  },
  {
    label: "Count Frappulla Frappé",
    productId: "frappe-count-frappulla",
  },
];

export const HotDrinkItems: StockManagerItem[] = [
  {
    label: "Hot Cross Bun Latté",
    productId: "hot-cross-bun-latte",
  },
  {
    label: "Spiced Pumpkin Latté",
    productId: "spiced-pumpkin-latte",
  },
  {
    label: "Gingerbread Latté",
    productId: "gingerbread-latte",
  },
  {
    label: "Hot Chocolate Lamington",
    productId: "hot-chocolate-lamington",
  },
  {
    label: "Matcha Latté",
    productId: "matcha-latte",
  },
  {
    label: "Luxury Hot Chocolate",
    productId: "hot-luxury-chocolate",
  },
  {
    label: "Luxury Hot Caramel",
    productId: "hot-luxury-caramel",
  },
];

export const IcedDrinkItems: StockManagerItem[] = [
  {
    label: "Iced Matcha",
    productId: "iced-matcha",
  },
  {
    label: "Iced Latté",
    productId: "iced-latte",
  },
  {
    label: "Iced Mocha",
    productId: "iced-mocha",
  },
  {
    label: "Iced Americano",
    productId: "iced-americano",
  },
  {
    label: "Iced GingerBread",
    productId: "iced-gingerbread-latte",
  },
  {
    label: "Iced Cappuccino",
    productId: "iced-cappuccino",
  },
  {
    label: "Iced Long Macchiato",
    productId: "iced-long-macchiato",
  },
];

export const DrinkIngredientsItems: StockManagerItem[] = [
  {
    label: "Oat Milk",
    productId: "milk-oat",
  },
  {
    label: "Coconut Milk",
    productId: "milk-coconut",
  },
  {
    label: "Soy Milk",
    productId: "milk-soy",
  },
  {
    label: "Almond Milk",
    productId: "milk-almond",
  },
];

export const DrinkIngredientsProductIds: ProductId[] = DrinkIngredientsItems.map(
  (item) => item.productId
);

export const BreadItems: StockManagerItem[] = [
  {
    label: "Molenburg Original Toast 700g",
    productId: "PLACEHOLDER-molenburg-bread",
  },
  {
    label: "Nature's Fresh White Toast 700g",
    productId: "PLACEHOLDER-nature-bread",
  },
];

export const MilkItems: StockManagerItem[] = [
  {
    label: "Cow & Gate Standard 2L",
    productId: "PLACEHOLDER-cowgate-milk",
  },
  {
    label: "Meadow Fresh Original 2L",
    productId: "PLACEHOLDER-meadow-milk",
  },
];

export const StoreProductIds: ProductId[] = [...BreadItems, ...MilkItems].map(
  (item) => item.productId
);
