import client from "lib/apiClient/api";

import { ENDPOINTS } from "domain/core/configuration";
import {
  LoginResponseWithSiteName,
  LoginPayload,
  LoginResponse,
} from "domain/core/auth/types";
import { parseSiteNameFromEmail } from "domain/core/auth/helpers";

export const login = client.post<
  LoginResponse,
  LoginPayload,
  LoginResponseWithSiteName
>(ENDPOINTS.login, {
  transformResult: (result, payload) => ({
    ...result,
    siteName: parseSiteNameFromEmail(payload.email),
  }),
});

export const refreshToken = client.get<LoginResponse, string>(
  ENDPOINTS.refresh,
  {
    transformEndpoint: (payload, endpoint) => `${endpoint}/${payload}`,
  }
);
