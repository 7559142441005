import React, { useMemo } from "react";
import { useReduxState, useActions } from "re-reduced";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";

import unboundActions from "domain/actions";

import * as selectors from "domain/selectors";
import { getDueMinutes } from "domain/orders/helpers";
import { Order, SectionConfig } from "domain/orders/types";
import { SECTIONS } from "domain/orders/configuration";

import OrderPickUpCard from "ui/compounds/OrderPickUpCard";
import EmptyState from "ui/components/EmptyState";
import { useTicks } from "domain/core/app/hooks";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      overflow: "auto",
    },
    padding: {
      padding: theme.spacing(0, 2),
    },
    section: {
      display: "flex",
      flexDirection: "column",
    },
    sectionContent: {
      flex: 1,
      overflow: "scroll",
    },
  })
);

interface SectionProps extends SectionConfig {
  items: Order[];
  selectedId: string | null;
  onOrderClick(orderId: string | null): void;
}

export const ScheduledSection: React.FC<SectionProps> = (props) => {
  const classes = useStyles();

  if (!props.items.length) {
    return null;
  }

  return (
    <div className={classes.section}>
      <ListItem>
        <Badge
          color={props.badgeColor ?? "primary"}
          badgeContent={props.items.length}
        >
          <Typography color="textSecondary" className={classes.padding}>
            {props.heading}
          </Typography>
        </Badge>
      </ListItem>
      <Divider />
      <List className={classes.sectionContent}>
        {props.items.map((order, index) => (
          <ListItem key={`${order.id}-${index}`}>
            <OrderPickUpCard
              order={order}
              onClick={props.onOrderClick}
              selected={props.selectedId === order.id}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const stateSelectors = {
  orders: selectors.getScheduledOrders,
  selectedId: selectors.getSelectedOrderId,
};

const Scheduled: React.FC = () => {
  const classes = useStyles();
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectors);
  const { ticks } = useTicks(5000);

  const columns = useMemo(
    () =>
      SECTIONS.map((section) => {
        const sectionProps = {
          ...section,
          items: state.orders.filter((order) =>
            section.criteria(getDueMinutes(order))
          ),
          selectedId: state.selectedId,
          onOrderClick: actions.orders.setSelectedId,
        };
        return (
          <ScheduledSection key={sectionProps.heading} {...sectionProps} />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions.orders.setSelectedId,
      state.orders,
      state.selectedId,
      ticks /* this is included only so the memoised list updates, positioning the cards in the expected column */,
    ]
  );

  const hasOrders = state.orders.length > 0;

  return (
    <div className={classes.root}>
      {hasOrders ? columns : <EmptyState>No new orders yet</EmptyState>}
    </div>
  );
};

Scheduled.displayName = "Scheduled";

export default Scheduled;
