import { createReducer, reduce, fold, composeReducers } from "re-reduced";
import { combineReducers } from "redux";
import { persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import always from "ramda/src/always";

import { createRequestReducer, REQUEST_INITIAL_STATE } from "lib/reducers";
import { PERSIST_KEY } from "store/configuration";

import {
  AuthState,
  LoginResponseVariant,
  AuthRequestsState,
} from "domain/core/auth/types";
import actions from "domain/core/auth/actions";

const INITIAL_STATE: AuthState = {
  token: "",
  refreshToken: "",
  siteName: "",
  requests: {
    login: REQUEST_INITIAL_STATE,
  },
};

export const token = createReducer<string>(
  fold(
    [actions.login.success, actions.refreshToken.success],
    (payload: LoginResponseVariant) => payload.token
  ),
  INITIAL_STATE.token
);

export const refreshToken = createReducer<string>(
  fold(
    [actions.login.success, actions.refreshToken.success],
    (payload: LoginResponseVariant) => payload.refreshToken
  ),
  INITIAL_STATE.refreshToken
);

export const siteName = createReducer<string>(
  fold(actions.login.success, (payload) => payload.siteName),
  INITIAL_STATE.siteName
);

export const requests = combineReducers<AuthRequestsState>({
  login: createRequestReducer(actions.login),
});

const baseReducer = combineReducers<AuthState>({
  token,
  refreshToken,
  siteName,
  requests,
});

const logoutReducer = createReducer(
  [reduce(actions.logout, always(INITIAL_STATE))],
  INITIAL_STATE
);

const reducer = composeReducers(baseReducer, logoutReducer);

export const persistConfig: PersistConfig<AuthState> = {
  storage,
  key: `@barista:${PERSIST_KEY}:core/auth`,
  version: 2,
  blacklist: ["requests"],
};

export default persistReducer(persistConfig, reducer);
