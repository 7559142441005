import React from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { SnackbarProvider } from "notistack";

import * as serviceWorker from "serviceWorker";
import configureStore from "store/configureStore";
import "./reactotron";

import theme from "ui/theme";
import App from "ui/App";
import Loading from "ui/components/Loading";
import ErrorBoundary from "ui/components/ErrorBoundary";

// mainly for loading fonts
import "index.css";

import { version } from "../package.json";
import { getDisabledItems } from "domain/selectors";
import { makeStyles } from "@material-ui/core";

// add appVersion to global scope
global.appVersion = version;

const { store, persistor } = configureStore();

const useStyles = makeStyles({
  marginBottom: {
    marginBottom: 65,
  },
});

// Can access redux context in here
function RootInner() {
  const showDisabledItemsBanner = useSelector(getDisabledItems).length;
  const styles = useStyles();

  return (
    <PersistGate loading={<Loading />} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          preventDuplicate
          autoHideDuration={3000}
          maxSnack={3}
          className={showDisabledItemsBanner ? styles.marginBottom : undefined}
        >
          <>
            <CssBaseline />
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </>
        </SnackbarProvider>
      </ThemeProvider>
    </PersistGate>
  );
}

function Root() {
  return (
    <Provider store={store}>
      <RootInner />
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.register();
