import { Middleware } from "redux";
import { Action } from "re-reduced";
import { AxiosResponse } from "axios";

import { APIErrorData } from "lib/apiClient/createClient";
import { prettyPrint } from "lib/string";

import actions from "domain/core/actions";

export type FailedAction = Action<Error | AxiosResponse<APIErrorData>>;

export const isLoggableAction = (action: FailedAction) =>
  action.type.endsWith("FAILURE") &&
  action.type !== actions.app.logEvent.failure.type;

export const loggerMiddleware: Middleware = (store) => (next) => (
  action: FailedAction
) => {
  const { payload } = action;

  if (isLoggableAction(action)) {
    if (process.env.NODE_ENV === "development") {
      store.dispatch(
        actions.app.enqueueSnackbar({
          message: `Action failed: ${action.type.replace("_FAILURE", "")}`,
          variant: "error",
          collapsedMessage: prettyPrint(payload),
        })
      );
    } else {
      store.dispatch(
        actions.app.logEvent({
          kind: "Error",
          message: `Action failed: ${action.type.replace("_FAILURE", "")}`,
          error: action.payload,
        })
      );
    }

    if ("status" in payload && payload.status === 401) {
      store.dispatch(actions.auth.logout());
    }
  }

  return next(action);
};

export default loggerMiddleware;
