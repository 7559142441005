export const APP_NAME = process.env.REACT_APP_APP_NAME;

export const PRODUCTION_URL =
  process.env.REACT_APP_PRODUCTION_URL ?? "https://zbarista.co.nz";

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;

export const AUTO_CHECK_FOR_UPDATES_INTERVAL = Number(
  process.env.REACT_APP_AUTO_CHECK_FOR_UPDATES_INTERVAL ?? ONE_HOUR
);
