import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

import Chip from "@material-ui/core/Chip";

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { styles as chipStyles } from "@material-ui/core/Chip/Chip";

import { mergeClassNames } from "lib/react";

export interface ChipColor {
  root: string;
  label: string;
}

type KnownClassKeys = "root" | "sizeSmall" | "label" | "labelSmall";

const useStyles = makeStyles((theme) => {
  const styles = chipStyles(theme) as Record<KnownClassKeys, CSSProperties>;

  return createStyles(styles);
});

interface ChipProps {
  label: string;
  size?: "small" | "medium";
  color?: ChipColor;
  borderColor?: string;
}

export default function CustomChip(props: ChipProps) {
  const classes = useStyles();

  if (!props.color) {
    return (
      <Chip
        style={{ fontWeight: "bold" }}
        size={props.size}
        variant="outlined"
        label={props.label}
      />
    );
  }

  const rootClassName = mergeClassNames(
    classes.root,
    props.size === "small" ? classes.sizeSmall : ""
  );

  const labelClassName = mergeClassNames(
    classes.label,
    props.size === "small" ? classes.labelSmall : ""
  );

  return (
    <div
      className={rootClassName}
      style={{
        backgroundColor: props.color?.root,
        border: props.borderColor
          ? `1px solid ${props.borderColor}`
          : undefined,
      }}
    >
      <span
        className={labelClassName}
        style={{ color: props.color?.label, fontWeight: "bold" }}
      >
        {props.label}
      </span>
    </div>
  );
}
