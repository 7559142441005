import Reactotron from "reactotron-react-js";
import { reactotronRedux } from "reactotron-redux";

const reactotron = Reactotron.configure({ name: "Z Barista" }).use(
  reactotronRedux()
);

if (process.env.REACT_APP_ENVIRONMENT_NAME !== "production") {
  reactotron.connect();
}

export default reactotron;
