import { useEffect, useState, useCallback } from "react";
import { useReduxState, useActions } from "re-reduced";

import ActivityMonitor from "lib/ActivityMonitor";
import { FirestoreWatcher } from "lib/firebase";

import { getSiteId } from "domain/selectors";
import { ENVIRONMENT_NAME } from "domain/core/configuration";
import ordersActions from "domain/orders/actions";
import appActions from "domain/core/app/actions";
import logger from "lib/logger";

/**
 * Custom hook that forces a component to re-render at a given interval
 *
 * @param {number} [interval] - tick interval in ms, defaults to 1000
 */
export function useTicks(interval: number = 1000) {
  const [ticks, setTicks] = useState(0);

  useEffect(() => {
    const tick = () => setTicks(ticks + 1);
    const intervalId = setInterval(tick, interval);

    return () => clearInterval(intervalId);
  }, [ticks, interval]);

  return { ticks };
}

interface ActivityMonitorListeners {
  onInactive(): void;
  onActive(): void;
}

/**
 * Activity monitor custom hook
 *
 * @param {ActivityMonitorListeners} listeners - { onActive, onInactive }
 */
export function useActivityMonitor(listeners: ActivityMonitorListeners) {
  useEffect(() => {
    const activityMonitor = new ActivityMonitor();

    activityMonitor.addEventListener("onInactive", listeners.onInactive);
    activityMonitor.addEventListener("onActive", listeners.onActive);
    activityMonitor.start();

    return () => {
      activityMonitor.removeEventListener("onInactive", listeners.onInactive);
      activityMonitor.removeEventListener("onActive", listeners.onActive);
      activityMonitor.stop();
    };
  }, [listeners]);
}

const stateSelectorMap = {
  siteId: getSiteId,
};

const unboundActions = {
  fetchQueued: ordersActions.fetchQueued,
  logEvent: appActions.logEvent,
};

export function useFirestoreWatcher() {
  const state = useReduxState(stateSelectorMap);
  const actions = useActions(unboundActions);

  const handleSnapshot = useCallback(
    (/* data: firestore.DocumentData */) => {
      actions.fetchQueued();
    },
    [actions]
  );

  const handlError = useCallback(
    (error: Error) => {
      actions.logEvent({
        kind: "Error",
        error,
        message: "Failed to subscribe to pub/sub channel",
      });
    },
    [actions]
  );

  useEffect(() => {
    logger.info([`siteId: ${state.siteId}`]);

    const firestoreWatcher = new FirestoreWatcher(`${ENVIRONMENT_NAME}-sites`);

    if (state.siteId) {
      firestoreWatcher.startListening(state.siteId, handleSnapshot, handlError);
    }

    return () => {
      firestoreWatcher.stopListening();
    };
  }, [state.siteId, handleSnapshot, handlError]);
}
