import React, { useCallback } from "react";

import indexBy from "ramda/src/indexBy";
import uniqBy from "ramda/src/uniqBy";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";

import { OrderItem, OrderStatus, Option } from "domain/orders/types";
import CustomChip, { ChipColor } from "ui/components/CustomChip";
import { groupBy } from "ramda";
import { grey } from "@material-ui/core/colors";

const hasMilkType = (title: string) =>
  ["Blue", "Trim", "Soy", "Almond", "Coconut", "Oat"].includes(title);

const isTypeMuffin = (title: string) => title.includes("Muffin");

function optionToColor(
  title: string,
  isDefault?: boolean
): ChipColor | undefined {
  if (isTypeMuffin(title)) {
    return { root: "#0000FF", label: "#FFF" };
  }
  if (hasMilkType(title)) {
    switch (title) {
      case "Blue":
        return { root: "#0000FF", label: "#FFF" };
      case "Trim":
        return { root: "#04821D", label: "#FFF" };
      case "Soy":
        return { root: "#C9E1A8", label: "#333" };
      case "Almond":
        return { root: "#D88D43", label: "#FFF" };
      case "Coconut":
        return { root: "#7DC0D8", label: "#333" };
      case "Oat":
        return { root: "#F7DC9C", label: "#333" };
      default:
        return undefined;
    }
  }

  if (isDefault === undefined || isDefault) {
    // use CustomChip's default colours
    return undefined;
  }

  if (!isDefault || Number(title.substring(0, 1)) > 1) {
    return { root: "#C0C0C0", label: "#000" };
  }
}

const useStyles = makeStyles((theme) => {
  return createStyles({
    items: {
      flex: 1,
    },
    optionsContainer: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    row: {
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    milkExtraHotOption: {
      "& > *": {
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5),
      },
    },
    customisationOption: {
      border: "solid 1px",
    },
    itemDetailsContainer: {
      display: "flex",
    },
    lisItem: {
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    listItemSelected: {
      backgroundColor: "#E8FFE8",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    note: {
      width: "220px",
      overflow: "hidden",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
  });
});

interface OrderItemOptionsProps {
  options: string | Option[];
  productId: string;
}
function OrderItemOptions(props: OrderItemOptionsProps) {
  const classes = useStyles();

  if (typeof props.options !== "string") {
    const options = props.options.slice(1);
    const milkAndExtraHotOptions = options.filter(
      (option) => option.attributeId === "milk" || option.id === "extra-hot"
    );
    const groupByCustomisations = groupBy((item) => item.attributeId, options);

    return (
      <div className={classes.optionsContainer}>
        <div className={classes.milkExtraHotOption}>
          {milkAndExtraHotOptions.map((option) => (
            <CustomChip
              key={option.id}
              size="small"
              label={option.title}
              color={optionToColor(option.title, option.isDefault)}
            />
          ))}
        </div>

        {Object.keys(groupByCustomisations).map((key) => {
          if (["milk", "extra-hot"].includes(key)) {
            return undefined;
          }

          const customisations = groupByCustomisations[key];

          return (
            <div key={key} className={classes.rowContainer}>
              {customisations.map((option) => (
                <div key={option.id}>
                  <CustomChip
                    key={option.id}
                    size="small"
                    label={option.title}
                    color={optionToColor(option.title, option.isDefault)}
                  />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classes.optionsContainer}>
      {typeof props.options === "string" &&
        props.options
          .split(/,\s+/)
          .map((option) => (
            <CustomChip
              key={option}
              size="small"
              label={option}
              color={optionToColor(option)}
            />
          ))}
    </div>
  );
}

interface Props {
  orderItems: OrderItem[];
  orderStatus: OrderStatus;
  groupedIds: string[];
  orderGroupedById: { [index: string]: OrderItem[] };
}

const OrderItems: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { groupedIds, orderGroupedById } = props;
  const indexedByProductId = indexBy(
    (item) => item.productId,
    props.orderItems
  );

  const renderCustomisation = useCallback(
    (item: OrderItem) => {
      return (
        <div key={item.productId}>
          <div className={classes.itemDetailsContainer}>
            <OrderItemOptions
              productId={item.productId}
              options={item.options}
            />
          </div>
          <br />
          {item.notes && (
            <div className={classes.note}>
              <b>Note: </b> {item.notes}
            </div>
          )}
        </div>
      );
    },
    [classes.itemDetailsContainer, classes.note]
  );

  return (
    <div className={classes.items}>
      <List>
        {groupedIds.map((id, index) => {
          const stringFromId = id.substring(0, id.indexOf(":"));
          const numberFromId = Number(id.substring(id.indexOf(":") + 1));
          const item = indexedByProductId[stringFromId];
          const byProductId = orderGroupedById[item.productId];
          const byUniqueProduct = uniqBy((item) => item, byProductId);
          const uniqueItem =
            byUniqueProduct.length > 1 && byUniqueProduct[numberFromId];
          const sizeOption = uniqueItem
            ? uniqueItem.options[0]
            : item.options[0];
          const productCount =
            byUniqueProduct.length > 1 && uniqueItem
              ? byProductId.filter(
                  (object) =>
                    JSON.stringify(object) === JSON.stringify(uniqueItem)
                ).length
              : byProductId.length;
          const countString = productCount > 1 ? `${productCount}x` : null;

          /**
           * Pies is an experiment - this could change in the future
           *
           * @TODO Revisit this
           */
          const isPies = item.productId.includes("pie");

          /**
           * Invert the logic if it's pies
           */
          const color = isPies
            ? {
                root: "#FFF",
                label: "#000",
              }
            : {
                root: "#000",
                label: "#FFF",
              };

          return (
            <div key={`${item.productId}-${index}`}>
              <ListItem className={classes.lisItem}>
                <div>
                  <Typography variant="h6">
                    {countString} {item.title}{" "}
                    {sizeOption && typeof sizeOption !== "string" && (
                      <CustomChip
                        key={sizeOption.id}
                        size="small"
                        label={sizeOption.title}
                        color={color}
                        borderColor={grey[500]}
                      />
                    )}
                  </Typography>
                  {uniqueItem
                    ? renderCustomisation(uniqueItem)
                    : byUniqueProduct.map(renderCustomisation)}
                </div>
              </ListItem>
              {index !== groupedIds.length - 1 && <Divider />}
            </div>
          );
        })}
      </List>
    </div>
  );
};

OrderItems.displayName = "OrderItems";

export default OrderItems;
