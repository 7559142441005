import axios from "axios";

import createClient from "./createClient";

const client = axios.create({
  baseURL: process.env.REACT_APP_LOGGING_ENDPOINT,
  timeout: 5000,
});

export default createClient(client);
