import React from "react";
import { Route, withRouter, Switch } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { ROUTES } from "domain/core/configuration";

import MainAppBar from "ui/compounds/MainAppBar";

import StockManager from "ui/pages/StockManagement/StockManager";
import Orders from "ui/pages/Orders";
import NewOrdersAlert from "ui/compounds/NewOrdersAlert";
import SiteOfflineAlert from "ui/compounds/SiteOfflineAlert";
import Snackbar from "ui/compounds/Snackbar";
import { DisabledItemsBar } from "ui/compounds/DisabledItemsBar";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    overscrollBehavior: "none", // disables pull-to-refresh
  },
}));

export const Main: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MainAppBar />
      <Switch>
        <Route path={ROUTES.stockManager} component={StockManager} />
        <Route path={ROUTES.orders} component={Orders} />
      </Switch>
      <NewOrdersAlert />
      <SiteOfflineAlert />
      <Snackbar />
      <DisabledItemsBar />
    </div>
  );
};

Main.displayName = "Main";

export default withRouter(Main);
