import React, { useEffect, useMemo, useRef, useCallback } from "react";
import { useReduxState, useActions } from "re-reduced";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";

import { playOrPauseAudio, pauseAndResetAudio } from "lib/audio";

import * as selectors from "domain/orders/selectors";
import unboundOrdersActions from "domain/orders/actions";
import unboundAppActions from "domain/core/app/actions";

import FullScreenAlert, {
  Circle,
  Headline,
  SubText,
} from "ui/components/FullScreenAlert";

const AUDIO_TAG_ID = "new_orders_alert_audio";

const unboundActions = {
  app: unboundAppActions,
  orders: unboundOrdersActions,
};

const stateSelectors = {
  queuedIds: selectors.getQueuedIds,
  acknowledgeQueue: selectors.getOrdersRequestState("acknowledgeQueue"),
  fetchAcknowledged: selectors.getOrdersRequestState("fetchAcknowledged"),
};

const NewOrdersAlert: React.FC = () => {
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectors);

  const isLoading = useMemo(
    () =>
      state.acknowledgeQueue.status === "Pending" ||
      state.fetchAcknowledged.status === "Pending",
    [state.acknowledgeQueue.status, state.fetchAcknowledged.status]
  );

  const hasOrdersInQueue = useMemo(() => Boolean(state.queuedIds.length), [
    state.queuedIds,
  ]);

  const audioRef = useRef<HTMLAudioElement>();

  useEffect(() => {
    const alertAudio = document.getElementById(AUDIO_TAG_ID) as
      | HTMLAudioElement
      | undefined;

    audioRef.current = alertAudio;
  }, []);

  useEffect(() => {
    async function retryPlayOrPauseAudio(shouldPlay: boolean) {
      if (audioRef.current) {
        await playOrPauseAudio(audioRef.current, shouldPlay);
      } else {
        throw new Error("Audio not ready after 1s delay");
      }
    }

    async function task(shouldPlay: boolean) {
      try {
        if (audioRef.current) {
          await playOrPauseAudio(audioRef.current, shouldPlay);
        } else {
          setTimeout(() => retryPlayOrPauseAudio(shouldPlay), 1000);
        }
      } catch (error) {
        const info =
          JSON.stringify(error) === "{}"
            ? "Audio playback aborted by the browser"
            : error;

        return actions.app.logEvent({
          kind: "Error",
          message: "Failed to play alert audio.",
          info,
        });
      }
    }

    task(hasOrdersInQueue);
  }, [hasOrdersInQueue, actions.app]);

  const handleConfirm = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (audioRef.current) {
      pauseAndResetAudio(audioRef.current);
    }

    actions.orders.acknowledgeQueue();
  }, [actions.orders, isLoading]);

  if (!hasOrdersInQueue) {
    return null;
  }

  const amountInQueue = state.queuedIds.length;
  const content = isLoading ? "…" : amountInQueue;
  const tagLine = amountInQueue === 1 ? `New order` : `New orders`;

  return (
    <FullScreenAlert onClick={handleConfirm} color={blue[600]}>
      <Circle color={red[600]}>{content}</Circle>
      <Headline>{isLoading ? "Receiving details" : tagLine}</Headline>
      {!isLoading && <SubText>Tap anywhere to continue</SubText>}
    </FullScreenAlert>
  );
};

NewOrdersAlert.displayName = "NewOrdersAlert";

export default NewOrdersAlert;
