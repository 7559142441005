import { createMuiTheme } from "@material-ui/core/styles";

export const COLORS = {
  blue: "rgb(0, 173, 255)",
  darkOrange: "#F7941E",
  lightOrange: "#FEBD0C",
};

// Create a theme instance.
const theme = createMuiTheme({});

export default theme;
