import client from "lib/apiClient/api";
import { Result } from "lib/types";
import { ENDPOINTS } from "domain/core/configuration";
import { getOrdersStatusesQueryString } from "domain/orders/helpers";

import {
  PatchOrdersRequest,
  PatchOrderRequest,
  OrdersQueueResult,
  OrdersResult,
  OrderStatus,
} from "domain/orders/types";

export const fetchQueued = client.get<OrdersQueueResult>(
  `${ENDPOINTS.getOrders}?compact=true&statuses=Paid`
);

export const fetchAcknowledged = client.get<OrdersResult, void>(
  `${ENDPOINTS.getOrders}?compact=false&statuses=Acknowledged`,
  {}
);

export const fetchAll = client.get<OrdersResult, OrderStatus[] | void>(
  `${ENDPOINTS.getOrders}?compact=false`,
  {
    transformEndpoint: (statuses, endpoint) =>
      `${endpoint}${getOrdersStatusesQueryString(statuses)}`,
  }
);

export const patchSingle = client.patch<Result<string>, PatchOrderRequest>(
  ENDPOINTS.patchOrders,
  {
    transformEndpoint: (payload, endpoint) => `${endpoint}/${payload.orderId}`,
  }
);

export const patchMany = client.patch<Result<string[]>, PatchOrdersRequest>(
  ENDPOINTS.patchOrders
);
