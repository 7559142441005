import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useReduxState, useActions } from "re-reduced";

import { Router } from "react-router-dom";
import orange from "@material-ui/core/colors/orange";

import { browserHistory } from "lib/browser";
import { PRODUCTION_URL } from "domain/core/app/configuration";
import * as selectors from "domain/core/selectors";
import unboundActions from "domain/core/app/actions";
import { useActivityMonitor, useFirestoreWatcher } from "domain/core/app/hooks";
import { useSnackbar } from "notistack";

import SignIn from "ui/pages/SignIn";
import Splash from "ui/compounds/Splash";

import Main from "ui/pages/Main";
import FullScreenAlert, {
  Circle,
  Headline,
  SubText,
} from "ui/components/FullScreenAlert";
import { NoInternetDialog } from "./components/NoInternetDialog";

const stateSelectors = {
  isAuthenticated: selectors.getIsAuthenticated,
  isBootstrapped: selectors.getIsAppBootstrapped,
  isOnline: selectors.getIsSiteOnline,
  onlineStatusChangeResason: selectors.getOnlineStatusChangeReason,
};

const linkStyle: React.CSSProperties = {
  color: "whitesmoke",
  fontWeight: "bold",
};

const App: React.FC = () => {
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectors);
  const snackbar = useSnackbar();

  const [hasValidHTTPProtocol, setHasValidHTTPProtocol] = useState(false);

  useEffect(() => {
    const { protocol, hostname } = window.location;

    const isProtocolValid = protocol === "https:" || hostname === "localhost";

    setHasValidHTTPProtocol(isProtocolValid);
  }, []);

  useFirestoreWatcher();

  useEffect(() => {
    if (hasValidHTTPProtocol) {
      actions.bootstrap();
    }
  }, [actions, hasValidHTTPProtocol]);

  const activityListeners = useMemo(
    () => ({
      onInactive() {
        actions.inactive();
      },
      onActive() {
        actions.restored();
      },
    }),
    [actions]
  );

  const [showOfflineDialog, setShowOfflineDialog] = useState(false);

  const handleCloseInternetDialog = useCallback(() => {
    setShowOfflineDialog(false);
    actions.enqueueSnackbar({
      id: "no-inet-conn",
      message: "You're not connected to the internet",
      variant: "error",
      persist: true,
    });
  }, [actions]);

  useEffect(() => {
    const handleInternetStatusChange = () => {
      if (navigator.onLine) {
        setShowOfflineDialog(false);
        snackbar.closeSnackbar("no-inet-conn");
        actions.enqueueSnackbar({
          variant: "info",
          message: "You're back online",
        });
      } else {
        setShowOfflineDialog(true);
      }
    };

    window.addEventListener("online", handleInternetStatusChange);
    window.addEventListener("offline", handleInternetStatusChange);

    return () => {
      window.removeEventListener("online", handleInternetStatusChange);
      window.removeEventListener("offline", handleInternetStatusChange);
    };
  }, [handleCloseInternetDialog, actions, snackbar]);

  useActivityMonitor(activityListeners);

  if (!hasValidHTTPProtocol) {
    return (
      <FullScreenAlert color={orange[500]}>
        <Circle still color={orange[800]}>
          !
        </Circle>
        <Headline>Invalid Barista URL</Headline>
        <SubText>
          {"Go to "}
          <a style={linkStyle} href={PRODUCTION_URL}>
            {PRODUCTION_URL}
          </a>
        </SubText>
      </FullScreenAlert>
    );
  }

  if (!state.isBootstrapped) {
    return <Splash />;
  }

  const SubRouteComponent = state.isAuthenticated ? Main : SignIn;

  return (
    <Router history={browserHistory}>
      <SubRouteComponent />
      <NoInternetDialog
        open={showOfflineDialog}
        onDismiss={handleCloseInternetDialog}
      />
    </Router>
  );
};

App.displayName = "App";

export default App;
