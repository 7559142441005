import React, { useState } from "react";
import { useActions, useReduxState } from "re-reduced";

import StyledSwitch from "ui/components/StyledSwitch";
import actions from "domain/core/app/actions";
import {
  getDisabledItems,
  getDisabledItemsRequestState,
} from "domain/selectors";
import { REQUEST_STATUS } from "lib/types";

import {
  LoadingOverlay,
  Container,
  SectionContainer,
  SectionHeaderContainer,
  SectionHeader,
  OptionContainer,
  isProductDisabled,
  handleToggleChange,
  ActionPendingConfirmation,
  ConfirmChangeStockAvailabilityModal,
} from "./Shared";
import { BreadItems, MilkItems } from "domain/core/app/stockConstants";

const unboundActions = {
  updateProductAvailability: actions.updateProductAvailability,
};

const stateSelectorMap = {
  disabledItems: getDisabledItems,
  disabledItemsRequestState: getDisabledItemsRequestState,
};

export default function Store() {
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectorMap);

  const isLoading = state.disabledItemsRequestState === REQUEST_STATUS.Pending;

  const [actionPendingConfirmation, setActionPendingConfirmation] = useState<
    ActionPendingConfirmation
  >();

  const handleConfirmDialogClose = () => {
    setActionPendingConfirmation(undefined);
  };

  const handleConfirmDialogConfirm = () => {
    if (actionPendingConfirmation) {
      actions.updateProductAvailability({
        productIds: [actionPendingConfirmation.item.productId],
        disableOrEnable: actionPendingConfirmation.disableOrEnable,
      });

      handleConfirmDialogClose();
    }
  };

  return (
    <Container>
      <SectionContainer>
        <LoadingOverlay isLoading={isLoading} />

        <SectionHeaderContainer>
          <SectionHeader>Bread</SectionHeader>
        </SectionHeaderContainer>
        {BreadItems.map((item) => (
          <OptionContainer
            key={item.productId}
            control={
              <StyledSwitch
                onChange={handleToggleChange(
                  item,
                  isLoading,
                  setActionPendingConfirmation
                )}
                checked={
                  !isProductDisabled(item.productId, state.disabledItems)
                }
              />
            }
            label={item.label}
          />
        ))}
      </SectionContainer>

      <SectionContainer>
        <LoadingOverlay isLoading={isLoading} />

        <SectionHeaderContainer>
          <SectionHeader>Milk</SectionHeader>
        </SectionHeaderContainer>
        {MilkItems.map((item) => (
          <OptionContainer
            key={item.productId}
            control={
              <StyledSwitch
                onChange={handleToggleChange(
                  item,
                  isLoading,
                  setActionPendingConfirmation
                )}
                checked={
                  !isProductDisabled(item.productId, state.disabledItems)
                }
              />
            }
            label={item.label}
          />
        ))}
      </SectionContainer>

      <ConfirmChangeStockAvailabilityModal
        actionPendingConfirmation={actionPendingConfirmation}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDialogConfirm}
      />
    </Container>
  );
}
