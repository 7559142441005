import React, { useState } from "react";

import actions from "domain/core/app/actions";
import {
  getDisabledCustomisationItems,
  getDisabledCustomisationItemsRequestState,
} from "domain/selectors";
import { useActions, useReduxState } from "re-reduced";
import { REQUEST_STATUS } from "lib/types";
import {
  ActionPendingConfirmation,
  ConfirmChangeStockAvailabilityModal,
  Container,
  handleStockStatusToggleChange,
  handleToggleChange,
  isCustomisationItemDisabled,
  isCustomisationItemOutOfStock,
  isNoneCustomisationItemStock,
  LoadingOverlay,
  OptionContainer,
  SectionContainer,
  SectionHeader,
  SectionHeaderContainer,
  SectionList,
} from "./Shared";
import StyledSwitch from "ui/components/StyledSwitch";
import { DrinkIngredientsItems } from "domain/core/app/stockConstants";

const unboundActions = {
  updateCustomisationAvailability: actions.updateCustomisationAvailability,
};

const stateSelectorMap = {
  disabledCustomisationItems: getDisabledCustomisationItems,
  disableCustomisationRequestState: getDisabledCustomisationItemsRequestState,
};

export default function DrinkIngredients() {
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectorMap);

  const isLoading =
    state.disableCustomisationRequestState === REQUEST_STATUS.Pending;

  const [actionPendingConfirmation, setActionPendingConfirmation] = useState<
    ActionPendingConfirmation
  >();

  const handleConfirmDialogClose = () => {
    setActionPendingConfirmation(undefined);
  };

  const handleConfirmDialogConfirm = () => {
    if (actionPendingConfirmation) {
      if (actionPendingConfirmation.isForStock) {
        actions.updateCustomisationAvailability({
          productId: actionPendingConfirmation.item.productId,
          disableOrEnable: actionPendingConfirmation.disableOrEnable,
          isTemporary: actionPendingConfirmation.disableOrEnable === "enable",
        });
      } else {
        actions.updateCustomisationAvailability({
          productId: actionPendingConfirmation.item.productId,
          disableOrEnable: actionPendingConfirmation.disableOrEnable,
          isTemporary: actionPendingConfirmation.disableOrEnable === "disable",
        });
      }
    }

    handleConfirmDialogClose();
  };

  return (
    <Container>
      <LoadingOverlay isLoading={isLoading} />
      <SectionContainer>
        <SectionHeaderContainer>
          <SectionHeader>Do you stock this type of milk?</SectionHeader>
        </SectionHeaderContainer>
        <SectionList>
          {DrinkIngredientsItems.map((item) => (
            <OptionContainer
              key={item.productId}
              control={
                <StyledSwitch
                  onChange={handleStockStatusToggleChange(
                    item,
                    isLoading,
                    setActionPendingConfirmation
                  )}
                  checked={
                    !isCustomisationItemOutOfStock(
                      item.productId,
                      state.disabledCustomisationItems
                    )
                  }
                />
              }
              label={item.label}
            />
          ))}
        </SectionList>
      </SectionContainer>
      {!isNoneCustomisationItemStock(
        state.disabledCustomisationItems,
        DrinkIngredientsItems
      ) && (
        <SectionContainer>
          <SectionHeaderContainer>
            <SectionHeader>Is it available?</SectionHeader>
          </SectionHeaderContainer>
          <SectionList>
            {DrinkIngredientsItems.map(
              (item) =>
                !isCustomisationItemOutOfStock(
                  item.productId,
                  state.disabledCustomisationItems
                ) && (
                  <OptionContainer
                    key={item.productId}
                    control={
                      <StyledSwitch
                        onChange={handleToggleChange(
                          item,
                          isLoading,
                          setActionPendingConfirmation
                        )}
                        checked={
                          !isCustomisationItemDisabled(
                            item.productId,
                            state.disabledCustomisationItems
                          )
                        }
                      />
                    }
                    label={item.label}
                  />
                )
            )}
          </SectionList>
        </SectionContainer>
      )}
      <ConfirmChangeStockAvailabilityModal
        actionPendingConfirmation={actionPendingConfirmation}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDialogConfirm}
      />
    </Container>
  );
}
