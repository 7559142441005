import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import Reactotron from "../reactotron";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "domain/reducer";
import rootSaga from "store/middleware/saga";
import browserHistoryMiddleware from "store/middleware/browserHistory";
import loggerMiddleware from "store/middleware/logger";

const sagaMiddleware = createSagaMiddleware();

const middlewareStack = [
  sagaMiddleware,
  browserHistoryMiddleware,
  loggerMiddleware,
];

export default function configureStore() {
  const storeEnhancer = composeWithDevTools(
    applyMiddleware(...middlewareStack)
  );
  const reactotronEnhancer = compose(
    storeEnhancer,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    Reactotron.createEnhancer()
  );
  const store =
    process.env.NODE_ENV === "development"
      ? createStore(rootReducer, reactotronEnhancer)
      : createStore(rootReducer, applyMiddleware(...middlewareStack));
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}
