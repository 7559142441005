import { SagaIterator } from "redux-saga";
import { takeLatest, all, put, call, select } from "redux-saga/effects";
import { Action } from "re-reduced";

import pathEq from "ramda/src/pathEq";

import { setToken } from "lib/apiClient/api";

import actions from "domain/core/actions";
import * as api from "domain/core/auth/api";
import * as selectors from "domain/core/auth/selectors";
import {
  LoginResponse,
  LoginPayload,
  LoginResponseWithSiteName,
} from "domain/core/auth/types";
import { encodeToken } from "domain/core/auth/helpers";
import { APIErrorResponse } from "lib/apiClient/createClient";

export default function* AuthSagaWatcher(): SagaIterator {
  yield all([
    takeLatest(actions.auth.login.type, login),
    takeLatest(
      [actions.app.bootstrap.success.type, actions.auth.refreshToken.type],
      refreshToken
    ),
  ]);
}

export function* login(action: Action<LoginPayload>): SagaIterator {
  yield put(actions.auth.login.request());

  try {
    const response: LoginResponseWithSiteName = yield call(api.login, {
      ...action.payload,
      email: action.payload.email.trim(),
    });

    yield call(setToken, response.token);
    yield put(
      actions.auth.login.success({
        ...response,
        token: encodeToken(response.token),
        refreshToken: encodeToken(response.refreshToken),
      })
    );
    yield put(actions.app.bootstrap());
  } catch (error) {
    yield put(actions.auth.login.failure(error as APIErrorResponse));
  }
}

export function* refreshToken(): SagaIterator {
  const refreshToken: string = yield select(selectors.getRefreshToken);

  if (!refreshToken) {
    return;
  }

  const isTokenNearExpiry: boolean = yield select(
    selectors.getIsTokenExpiryWithin7Days
  );

  if (!isTokenNearExpiry) {
    return;
  }

  try {
    const response: LoginResponse = yield call(api.refreshToken, refreshToken);

    yield call(setToken, response.token);
    yield put(
      actions.auth.refreshToken.success({
        ...response,
        token: encodeToken(response.token),
        refreshToken: encodeToken(response.refreshToken),
      })
    );
  } catch (error) {
    if (pathEq(["data", "status"], 400, error)) {
      yield put(actions.auth.logout());
    }
    yield put(actions.auth.refreshToken.failure(error as Error));
  }
}
