import React from "react";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { toLocaleString } from "lib/date-time";
import { ORDER_ACTIONS } from "domain/orders/configuration";
import { ActionableStatus, OrderDetails } from "domain/orders/types";

interface Props {
  order: OrderDetails;
  activeStepIndex: number;
}

const StatusTransitions: React.FC<Props> = (props) => {
  const { order, activeStepIndex } = props;

  if (order.transitions.length <= 1) {
    return null;
  }

  return (
    <>
      <Divider />
      <Stepper
        nonLinear
        alternativeLabel
        activeStep={activeStepIndex}
        style={{ padding: "12px 0" }}
      >
        {order.transitions.map((transition, index) => {
          const transitionStatus =
            ORDER_ACTIONS[transition.to as ActionableStatus];

          return (
            <Step key={String(transition.date)}>
              <StepLabel color={transitionStatus.color}>
                <Typography
                  style={{ marginTop: -12 }}
                  color={
                    index === activeStepIndex
                      ? transitionStatus.color
                      : undefined
                  }
                >
                  {transitionStatus.shortLabel ?? transitionStatus.label}
                </Typography>
                {toLocaleString(transition.date)}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};

StatusTransitions.displayName = "StatusTransitions";

export default StatusTransitions;
