export type EventType = "onActive" | "onInactive";

export type Callback = () => void;

export interface ActivityMonitorConfig {
  /**
   * The interval (in ms) that defines how often the monitor will check for activity
   */
  interval?: number;
  /**
   * Defines how long the page must be inactive to fire an 'onInactive' callback
   */
  threshold?: number;
}

/**
 * Activity Monitor
 *
 */
export default class ActivityMonitor {
  private intervalId = 0;
  private rafId = 0;
  private interval = 1000;
  private threshold = 10000;
  private lastFrameTimestamp = 0;
  private isActive = true;

  private handlers: Record<EventType, Callback[]> = {
    onActive: [],
    onInactive: [],
  };

  public isRunning = false;

  public constructor(config: ActivityMonitorConfig = {}) {
    if (config.interval) {
      this.interval = config.interval;
    }
    if (config.threshold) {
      this.threshold = config.threshold;
    }
  }

  public addEventListener(event: EventType, fn: Callback) {
    this.handlers[event].push(fn);
  }

  public removeEventListener(event: EventType, fn: Callback) {
    const index = this.handlers[event].indexOf(fn);
    this.handlers[event].splice(index, 1);
  }

  private handleInterval = () => {
    const now = Date.now();
    const delta = now - this.lastFrameTimestamp;
    const isPastThreshold = delta >= this.threshold;

    if (this.isActive && isPastThreshold) {
      this.isActive = false;
      this.handlers.onInactive.forEach((fn) => fn());
    } else if (!this.isActive && !isPastThreshold) {
      this.isActive = true;
      this.handlers.onActive.forEach((fn) => fn());
    }
  };

  private handleFrame = () => {
    this.lastFrameTimestamp = Date.now();
    const recurOnTimeout = () => {
      this.rafId = window.requestAnimationFrame(this.handleFrame);
    };
    window.setTimeout(recurOnTimeout, this.interval);
  };

  public start() {
    if (!this.isRunning) {
      this.lastFrameTimestamp = Date.now();
      this.rafId = window.requestAnimationFrame(this.handleFrame);
      this.intervalId = window.setInterval(this.handleInterval, this.interval);
      this.isRunning = true;
    }
  }

  public stop() {
    if (this.isRunning) {
      window.clearInterval(this.intervalId);
      window.cancelAnimationFrame(this.rafId);
      this.isRunning = false;
    }
  }
}
