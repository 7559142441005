import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/remote-config";

import logger from "lib/logger";

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(FIREBASE_CONFIG);

type OnSnapshotData = (data: firebase.firestore.DocumentData) => void;
type OnError = (error: firebase.firestore.FirestoreError | Error) => void;

export class FirestoreWatcher {
  private collectionPath?: string;
  private documentId?: string;
  private collectionRef?: firebase.firestore.CollectionReference = undefined;

  private isListening = false;

  constructor(collectionPath: string) {
    this.collectionPath = collectionPath;
    this.collectionRef = firebase.firestore().collection(collectionPath);
  }

  public startListening(
    documentId: string,
    onData: OnSnapshotData,
    onError?: OnError
  ) {
    this.documentId = documentId;
    this.isListening = true;

    if (this.collectionRef) {
      const documentRef = this.collectionRef.doc(documentId);

      documentRef.onSnapshot(this.handleDocSnapshot(onData), onError);
    }
  }

  public stopListening() {
    this.isListening = false;
  }

  private handleDocSnapshot = (onData: OnSnapshotData) => (
    doc: firebase.firestore.DocumentSnapshot
  ) => {
    if (!this.isListening || !doc.exists) {
      return;
    }

    const data = doc.data();

    if (data) {
      logger.log(["[FirestoreWatcher] Data received", data]);
      onData(data);
    } else {
      logger.warn(
        `[FirestoreWatcher] Document not found: ${this.collectionPath}/${this.documentId}`
      );
    }
  };
}

// Firebase remote config

const remoteConfig = firebase.remoteConfig();

remoteConfig.settings = {
  minimumFetchIntervalMillis: 60000,
  fetchTimeoutMillis: 60000,
};

export const getAllRemoteConfig = async () => {
  await remoteConfig.fetchAndActivate();
  const snapshot = await remoteConfig.getAll();

  return Object.entries(snapshot).map(([key, parameter]) => {
    return {
      key,
      value: parameter.asString(),
      source: parameter.getSource(),
    };
  });
};
