import { createActions } from "re-reduced";

import { APIErrorResponse } from "lib/apiClient/createClient";

import {
  LoginPayload,
  LoginResponseWithSiteName,
  LoginResponse,
} from "domain/core/auth/types";

export default createActions("CORE/AUTH", (create) => ({
  login: create.asyncAction<
    LoginResponseWithSiteName,
    LoginPayload,
    APIErrorResponse
  >(),
  logout: create.action(),
  refreshToken: create.asyncAction<LoginResponse, string>(),
}));
