import React, { useCallback, useMemo, ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { red, orange, blue } from "@material-ui/core/colors";

import { toLocaleString } from "lib/date-time";
import { Order, OrderStatus, ActionableStatus } from "domain/orders/types";
import { getDueMinutes, formatDueTime } from "domain/orders/helpers";
import { ORDER_ACTIONS } from "domain/orders/configuration";

const pop = keyframes`
  0% {
    transform: scale(0)
  }
  80% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
`;

const OrderCard = styled.div`
  min-height: 100px;
  border-radius: 2px;
  animation: ${pop} ease-in-out 0.3s;
  transition: boxShadow 0.5s ease-in;
  background-color: #fff;
`;

interface DueTimeProps {
  dueMinutes: number;
  status: OrderStatus;
}

export const DueTime: React.FC<DueTimeProps> = (props) => {
  if (props.status !== "Acknowledged") {
    return null;
  }

  const absDueMinutes = Math.abs(props.dueMinutes);

  return (
    <Typography color={absDueMinutes === 0 ? "error" : "primary"}>
      {formatDueTime(absDueMinutes, props.dueMinutes >= 0 ? "Due" : "Overdue")}
    </Typography>
  );
};

interface OrderContentProps {
  order: Order;
  className?: string;
}

export const OrderContent: React.FC<OrderContentProps> = (props) => {
  const numberOfItems = props.order.lines.length;
  const moreThanOneItem = numberOfItems > 1;

  return (
    <Typography className={props.className}>{`${numberOfItems} item${
      moreThanOneItem ? "s" : ""
    }`}</Typography>
  );
};

const useStyles = makeStyles(
  createStyles({
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    details: {
      margin: "0 10px",
    },
    orderContent: {
      fontSize: ".9em",
      whiteSpace: "nowrap",
      overflowX: "hidden",
      width: 200,
    },
    due: {},
    overdue: {},
  })
);

interface Props {
  order: Order;
  selected: boolean;
  onClick(orderId: string): void;
  dueMinutes?: number;
  children?: ReactNode;
}

const OrderPickUpCard: React.FC<Props> = (props) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    props.onClick(props.order.id);
  }, [props]);

  const dueMinutes = getDueMinutes(props.order);

  const boxShadow = useMemo(() => {
    const borderColor =
      dueMinutes < 0 ? red[500] : dueMinutes <= 5 ? orange[500] : blue[800];

    const color =
      props.order.status === "Acknowledged" ? borderColor : blue[800];

    return props.selected ? `0 0 4px 4px ${color}` : `0 0 0 2px ${color}`;
  }, [dueMinutes, props.selected, props.order.status]);

  const status = ORDER_ACTIONS[props.order.status as ActionableStatus];

  return (
    <OrderCard onClick={handleClick} style={{ boxShadow }}>
      <CardContent className={classes.content}>
        <div style={{ display: "flex" }}>
          <Avatar>{props.order.lines.length}x</Avatar>
          <div className={classes.details}>
            <Typography variant="h6">{props.order.customerName}</Typography>
            <Typography variant="subtitle2">
              {status.shortLabel ?? status.label}
              {" at "}
              {toLocaleString(props.order.statusUpdatedAt)}
            </Typography>
            <OrderContent
              className={classes.orderContent}
              order={props.order}
            />
            <DueTime dueMinutes={dueMinutes} status={props.order.status} />
          </div>
        </div>
        {props.children && <div>{props.children}</div>}
      </CardContent>
    </OrderCard>
  );
};

OrderPickUpCard.displayName = "OrderPickUpCard";

export default OrderPickUpCard;
