import format from "date-fns/format";

export type LogKind = "log" | "info" | "error" | "warn";

const defaultGetNow = () => new Date();

/**
 * Logs a message to the console (dev only)
 * @param {LogKind} kind
 * @param {string} message Message to be logged
 * @param getNow Function to return the current date (for testability)
 */
export const log = (kind: LogKind) => <T>(
  message: T,
  getNow: () => Date = defaultGetNow
) => {
  if (process.env.REACT_APP_ENVIRONMENT_NAME !== "production") {
    const logger = console[kind];
    const timeStamp = `[${format(getNow(), "HH:mm:ss:SSS")}]`;

    if (kind in console && typeof logger === "function") {
      if (Array.isArray(message)) {
        message.forEach((m) => logger(m, timeStamp));
      } else {
        logger(message, timeStamp);
      }
    }
  }
};

const logger: Record<LogKind, <T>(message: T, getNow?: () => Date) => void> = {
  log: log("log"),
  info: log("info"),
  warn: log("warn"),
  error: log("error"),
};

export default logger;
