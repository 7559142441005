import { createSelector } from "reselect";

import { BaristaState } from "domain/types";
import { OrdersRequestsState } from "domain/orders/types";
import {
  byDueTimeDescending,
  byStatusUpdateDescending,
  withTransitions,
  withParsedDateProps,
} from "domain/orders/helpers";
import { asArray } from "lib/selectors";

export const getOrdersState = (state: BaristaState) => state.orders;

export const getSelectedOrderId = createSelector(
  getOrdersState,
  (orders) => orders.selectedId
);

export const getOrdersItems = createSelector(
  getOrdersState,
  (orders) => orders.items
);

export const getOrdersItemsById = createSelector(
  getOrdersItems,
  (items) => items.byId
);

export const getOrdersItemsIdList = createSelector(
  getOrdersItems,
  (items) => items.idList
);

export const getOrdersTransitions = createSelector(
  getOrdersState,
  (orders) => orders.transitions
);

export const getOrdersRequests = createSelector(
  getOrdersState,
  (orders) => orders.requests
);

export const getOrdersAsArray = createSelector(
  getOrdersItemsById,
  getOrdersItemsIdList,
  (byId, idList) => asArray(byId, idList).map(withParsedDateProps)
);

export const getOrdersAsArrayWithTransitions = createSelector(
  getOrdersAsArray,
  getOrdersTransitions,
  (orders, transitions) =>
    orders.map(withTransitions(transitions)).sort(byStatusUpdateDescending)
);

export const getScheduledOrders = createSelector(getOrdersAsArray, (items) =>
  items
    .filter((order) => order.status === "Acknowledged")
    .sort(byDueTimeDescending)
);

export const getOrdersHistory = createSelector(
  getOrdersAsArrayWithTransitions,
  (items) =>
    items.filter(
      (order) =>
        order.status === "Cancelled" ||
        order.status === "PickedUp" ||
        order.status === "NotPickedUp" ||
        order.status === "Late"
    )
);

export const getScheduledOrdersCount = createSelector(
  getScheduledOrders,
  (orders) => orders.length
);

export const getOrdersReadyForCollection = createSelector(
  getOrdersAsArrayWithTransitions,
  (items) => items.filter((order) => order.status === "Made")
);

export const getReadyOrdersCount = createSelector(
  getOrdersReadyForCollection,
  (items) => items.length
);

export const getSelectedOrder = createSelector(
  getSelectedOrderId,
  getOrdersItems,
  getOrdersTransitions,
  (id, items, transitions) =>
    !id ? undefined : withTransitions(transitions)(items.byId[id])
);

export const getQueuedIds = createSelector(
  getOrdersState,
  (state) => state.queuedIds
);

export const getOrdersRequestState = (requestKey: keyof OrdersRequestsState) =>
  createSelector(getOrdersRequests, (requests) => requests[requestKey]);
