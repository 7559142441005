import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

interface Props {
  open: boolean;
  title: string;
  cancelLabel: string;
  confirmLabel: string;
  onClose(): void;
  onCancel(): void;
  onConfirm(): void;
}

const ConfirmDialog: React.FC<Props> = (props) => (
  <Dialog
    open={props.open}
    onClose={props.onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
    {props.children && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.children}
        </DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      <Button onClick={props.onCancel} color="secondary">
        {props.cancelLabel}
      </Button>
      <Button onClick={props.onConfirm} color="primary" autoFocus>
        {props.confirmLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.displayName = "ConfirmDialog";

export default ConfirmDialog;
