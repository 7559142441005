import isBefore from "date-fns/isBefore";
import differenceInMinutes from "date-fns/differenceInMinutes";
import isAfter from "date-fns/isAfter";
import allPass from "ramda/src/allPass";
import last from "ramda/src/last";
import assoc from "ramda/src/assoc";

import { parseDate } from "lib/date-time";
import { APIErrorData } from "lib/apiClient/createClient";

import { RouteKind } from "domain/core/configuration";
import { ORDER_ACTIONS, ERROR_CODES } from "domain/orders/configuration";
import {
  Order,
  ActionableStatus,
  OrderDetails,
  OrderStatusTransition,
  OrderStatus,
} from "domain/orders/types";

export const formatStatus = (status: string) =>
  status in ORDER_ACTIONS
    ? ORDER_ACTIONS[status as ActionableStatus].label
    : "";

export const getDueMinutes = (order: Order, now: Date = new Date()) =>
  differenceInMinutes(parseDate(order.dueTime), now);

export const getMinutesSinceStatusUpdated = (
  order: Order,
  now: Date = new Date()
) => differenceInMinutes(now, parseDate(order.statusUpdatedAt));

export const formatDueTime = (
  absMinutes: number,
  prefix: "Due" | "Overdue"
) => {
  const infix = prefix === "Due" ? "in" : "by";

  switch (absMinutes) {
    case 0:
      return "Due now";
    case 1:
      return `${prefix} ${infix} one minute`;
    default:
      return `${prefix} ${infix} ${absMinutes} minutes`;
  }
};

export const getLastStatusUpdateDate = (order: OrderDetails) =>
  parseDate(
    order.transitions.length
      ? last(order.transitions)?.date ?? order.statusUpdatedAt
      : order.statusUpdatedAt
  );

export const byDueTimeDescending = (a: Order, b: Order) => {
  const [dateA, dateB] = [a, b].map((x) => x.dueTime).map(parseDate);
  return isBefore(dateA, dateB) ? -1 : 1;
};

export const byStatusUpdateDescending = (a: OrderDetails, b: OrderDetails) => {
  const [dateA, dateB] = [a, b].map(getLastStatusUpdateDate);
  return isBefore(dateA, dateB) ? -1 : 1;
};

export const withTransitions = (
  transitions: Record<string, OrderStatusTransition[]>
) => (order: Order): OrderDetails => ({
  ...order,
  transitions: transitions[order.id] ?? [
    { date: order.statusUpdatedAt, to: order.status },
  ],
});

export const withDefaultTransition = (
  transitionsByOrderId: Record<string, OrderStatusTransition[]>,
  order: Order
) =>
  assoc(
    order.id,
    transitionsByOrderId[order.id] ?? [
      {
        date: order.statusUpdatedAt,
        to: order.status,
      },
    ],
    transitionsByOrderId
  );

export const isOrderTransitionAndAfter = (oneDayAgo: Date | number) =>
  allPass([
    // this is to prevent illegal states being added to the transitions
    (transition: OrderStatusTransition) => transition.to !== "Paid",
    // this is to enforce removing transitions older than 24h
    (transition: OrderStatusTransition) =>
      isAfter(parseDate(transition.date), oneDayAgo),
  ]);

export const getRouteFromStatus = (status: OrderStatus): RouteKind => {
  switch (status) {
    case "Acknowledged":
    case "Paid":
      return "orders";
    case "Made":
      return "collection";
    case "PickedUp":
    case "NotPickedUp":
    case "Late":
    case "Cancelled":
      return "history";
  }
};

export const getOrdersStatusesQueryString = (statuses: OrderStatus[] | void) =>
  statuses
    ? `&${statuses.map((status) => `statuses=${status}`).join("&")}`
    : "";

export const withParsedDateProps = (order: Order): Order => ({
  ...order,
  statusUpdatedAt: parseDate(order.statusUpdatedAt),
  dueTime: parseDate(order.dueTime),
});

export const isTransitioningToSameStatus = (error?: APIErrorData) => {
  return (
    error?.type === ERROR_CODES.INVALID_TRANSITION &&
    /Transition from `(\w+)` to `\1` is invalid/.test(error.detail)
  );
};
