import React, { useCallback } from "react";
import { useActions, useReduxState } from "re-reduced";

import Drawer from "@material-ui/core/Drawer";

import { PatchOrderRequest } from "domain/orders/types";
import unboundOrdersActions from "domain/orders/actions";
import unboundAppActions from "domain/core/app/actions";
import {
  getScheduledOrdersCount,
  getSelectedOrder,
  getOrdersRequestState,
  getReadyOrdersCount,
} from "domain/selectors";

import OrderDetails from "./OrderDetails";

const unboundActions = {
  app: unboundAppActions,
  orders: unboundOrdersActions,
};

const stateSelectors = {
  scheduledCount: getScheduledOrdersCount,
  readyCount: getReadyOrdersCount,
  order: getSelectedOrder,
  updateOrderStatus: getOrdersRequestState("updateStatus"),
};

const OrderDetailsDrawer: React.FC = () => {
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectors);

  const isTransitioning = state.updateOrderStatus.status === "Pending";

  const handleDrawerClose = useCallback(
    () => actions.orders.setSelectedId(null),
    [actions.orders]
  );

  const handleUpdateStatus = useCallback(
    (payload: PatchOrderRequest) => actions.orders.updateStatus(payload),
    [actions.orders]
  );

  return (
    <Drawer
      anchor="right"
      open={Boolean(state.order)}
      onClose={handleDrawerClose}
    >
      {state.order && (
        <OrderDetails
          order={state.order}
          isTransitioning={isTransitioning}
          onUpdateStatus={handleUpdateStatus}
        />
      )}
    </Drawer>
  );
};

OrderDetailsDrawer.displayName = "OrderDetailsDrawer";

export default OrderDetailsDrawer;
