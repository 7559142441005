import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { toLocaleString } from "lib/date-time";
import { ORDER_ACTIONS } from "domain/orders/configuration";
import {
  ActionableStatus,
  OrderDetails as IOrderDetails,
  PatchOrderRequest,
} from "domain/orders/types";
import { getDueMinutes } from "domain/orders/helpers";

import { DueTime } from "ui/compounds/OrderPickUpCard";
import OrderActions from "ui/compounds/OrderActions";
import StatusTransitions from "./StatusTransitions";
import NoteBalloon from "ui/components/NoteBalloon";
import OrderItems from "./OrderItems";
import { uniq, groupBy } from "ramda";
import styled from "styled-components";

const shadow = {
  colour: "rgba(255, 255, 255, 0)",
  transparency: "rgba(0, 0, 0, 0.2)",
  background: "rgba(0, 0, 0, 0)",
};

const Wrapper = styled.div`
  flex: 1;
  overflow-x: hidden;
  width: 320px;

  background: linear-gradient(white 30%, ${shadow.colour}),
    linear-gradient(${shadow.colour}, white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      ${shadow.transparency},
      ${shadow.background}
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        ${shadow.transparency},
        ${shadow.background}
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  background-attachment: local, local, local, scroll;
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      width: 350,
      height: "100vh",
      padding: 24,
    },
    header: {
      display: "flex",
      padding: "0 24px",
      alignItems: "center",
      marginBottom: 24,
    },
    headerLeft: {},
    headerRight: {
      paddingLeft: 12,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    body: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      overflow: "scroll",
    },
    sectionHeading: {
      marginTop: 16,
      fontWeight: "bold",
    },
    avatar: {
      fontWeight: "bold",
      color: "black",
    },
    notes: {},
  })
);

export const OrderNotes: React.FC = (props) => {
  const classes = useStyles();

  if (!props.children) {
    return null;
  }

  const baloonContent =
    typeof props.children === "string"
      ? `"${props.children.trim()}"`
      : props.children;

  return (
    <div className={classes.notes}>
      <Divider />
      <div className={classes.sectionHeading}>Customer note:</div>
      <NoteBalloon>{baloonContent}</NoteBalloon>
    </div>
  );
};

OrderNotes.displayName = "OrderNotes";

interface Props {
  order: IOrderDetails;
  isTransitioning: boolean;
  onUpdateStatus: (payload: PatchOrderRequest) => void;
}

const OrderDetails: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dueMinutes = getDueMinutes(props.order);
  const status = ORDER_ACTIONS[props.order.status as ActionableStatus];
  const orderItems = props.order.lines;
  const activeStepIndex = props.order.transitions.length - 1;
  const orderGroupedById = groupBy((item) => item.productId, orderItems);
  const groupedIds: string[] = [];

  for (const key of Object.keys(orderGroupedById)) {
    const orderItems = orderGroupedById[key];
    const unqiueObjects = uniq(orderItems);
    groupedIds.push(
      ...unqiueObjects.map((object, index) => `${object.productId}:${index}`)
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <Avatar className={classes.avatar}>{orderItems.length}x</Avatar>
          </div>
          <div className={classes.headerRight}>
            <Typography variant="h5">{props.order.customerName}</Typography>
            <DueTime dueMinutes={dueMinutes} status={props.order.status} />
            <Typography variant="subtitle2">
              <Typography color={status.color} component="span">
                {status.shortLabel ?? status.label}
              </Typography>
              {" at "}
              {toLocaleString(props.order.statusUpdatedAt)}
            </Typography>
          </div>
        </div>
        <StatusTransitions
          order={props.order}
          activeStepIndex={activeStepIndex}
        />
        <Divider />
        <Wrapper>
          <OrderItems
            orderItems={orderItems}
            orderStatus={props.order.status}
            groupedIds={groupedIds}
            orderGroupedById={orderGroupedById}
          />
          <OrderNotes>{props.order.notes}</OrderNotes>
        </Wrapper>
        <OrderActions
          order={props.order}
          isTransitioning={props.isTransitioning}
          onUpdateStatus={props.onUpdateStatus}
        />
      </div>
    </div>
  );
};

OrderDetails.displayName = "OrderDetails";

export default OrderDetails;
