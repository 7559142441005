import { combineReducers } from "redux";
import { PersistConfig, persistReducer } from "redux-persist";
import {
  createReducer,
  foldP,
  composeReducers,
  fold,
  reduce,
} from "re-reduced";
import storage from "redux-persist/lib/storage";

import addHours from "date-fns/addHours";

import identity from "ramda/src/identity";
import always from "ramda/src/always";
import evolve from "ramda/src/evolve";
import indexBy from "ramda/src/indexBy";
import lensPath from "ramda/src/lensPath";
import over from "ramda/src/over";
import mergeLeft from "ramda/src/mergeLeft";
import assoc from "ramda/src/assoc";
import dissoc from "ramda/src/dissoc";

import {
  createRequestReducer,
  createAsyncCollectionReducer,
  REQUEST_INITIAL_STATE,
  ASYNC_COLLECTION_INITIAL_STATE,
} from "lib/reducers";
import { AsyncCollection } from "lib/types";

import { PERSIST_KEY } from "store/configuration";
import { ORDERS_FALLBACK_POLL_INTERVAL } from "domain/orders/configuration";

import { OrdersState, Order, OrderStatusTransition } from "domain/orders/types";

import authActions from "domain/core/auth/actions";
import appActions from "domain/core/app/actions";
import actions from "domain/orders/actions";
import {
  withDefaultTransition,
  isOrderTransitionAndAfter,
} from "domain/orders/helpers";

const INITIAL_STATE: OrdersState = {
  items: ASYNC_COLLECTION_INITIAL_STATE,
  queuedIds: [],
  selectedId: null,
  requests: {
    fetchQueued: REQUEST_INITIAL_STATE,
    acknowledgeQueue: REQUEST_INITIAL_STATE,
    fetchAcknowledged: REQUEST_INITIAL_STATE,
    updateStatus: REQUEST_INITIAL_STATE,
  },
  transitions: {},
  pollingInterval: ORDERS_FALLBACK_POLL_INTERVAL,
};

export const items = composeReducers<AsyncCollection<Order>>(
  createAsyncCollectionReducer(actions.fetchAcknowledged, "id"),
  createReducer(
    [
      foldP(actions.updateStatus.success, (payload) =>
        over(lensPath(["byId", payload.id]), mergeLeft(payload))
      ),
      foldP(actions.refresh.success, (payload) =>
        evolve({
          byId: always(indexBy((order) => order.id, payload.items)),
          idList: always(payload.items.map((order) => order.id)),
        })
      ),
    ],
    INITIAL_STATE.items
  )
);

export const queuedIds = createReducer<string[]>(
  [
    fold(actions.fetchQueued.success, (payload) => payload.items),
    fold(actions.acknowledgeQueue.success, always(INITIAL_STATE.queuedIds)),
  ],
  INITIAL_STATE.queuedIds
);

const selectedId = createReducer<string | null>(
  fold(actions.setSelectedId, identity),
  INITIAL_STATE.selectedId
);

const requests = combineReducers({
  acknowledgeQueue: createRequestReducer(actions.acknowledgeQueue),
  fetchQueued: createRequestReducer(actions.fetchQueued),
  fetchAcknowledged: createRequestReducer(actions.fetchAcknowledged),
  updateStatus: createRequestReducer(actions.updateStatus),
});

const transitions = createReducer<Record<string, OrderStatusTransition[]>>(
  [
    fold(actions.updateStatus.success, (payload, state) =>
      assoc(
        payload.id,
        (state[payload.id] ?? []).concat({
          date: new Date(),
          to: payload.status,
        }),
        state
      )
    ),
    fold(
      [actions.fetchAcknowledged.success, actions.refresh.success],
      (payload, state) => {
        const patch = payload.items.reduce(withDefaultTransition, state);

        const oneDayAgo = addHours(Date.now(), -24);
        const isLegalTransition = isOrderTransitionAndAfter(oneDayAgo);

        return Object.keys(state).reduce((transitionsByOrderId, orderId) => {
          const currentTransitions = transitionsByOrderId[orderId];
          const nextTranstions = currentTransitions.filter(isLegalTransition);

          return nextTranstions.length
            ? assoc(orderId, nextTranstions, transitionsByOrderId)
            : dissoc(orderId, transitionsByOrderId);
        }, patch);
      }
    ),
  ],
  INITIAL_STATE.transitions
);

const pollingInterval = createReducer([], INITIAL_STATE.pollingInterval);

const baseReducer = combineReducers<OrdersState>({
  items,
  queuedIds,
  selectedId,
  requests,
  transitions,
  pollingInterval,
});

const logoutReducer = createReducer<OrdersState>(
  [
    reduce(authActions.logout, (state) => ({
      ...INITIAL_STATE,
      transitions: state.transitions,
    })),
    reduce(appActions.reset, always(INITIAL_STATE)),
  ],
  INITIAL_STATE
);

export const reducer = composeReducers(baseReducer, logoutReducer);

export const persistConfig: PersistConfig<OrdersState> = {
  storage,
  key: `@barista:${PERSIST_KEY}:orders`,
  version: 2,
  blacklist: ["requests", "selectedId", "queuedIds"],
};

export default persistReducer(persistConfig, reducer);
