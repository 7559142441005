export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;

export const ENDPOINTS = {
  getOrders: "/v3/orders",
  patchOrders: "/v2/orders",
  sitesStatus: "/v1/sites/online",
  login: "/v1/jwt/login",
  refresh: "/v1/jwt/refresh",
  inventory: "/v1/inventory",
} as const;

export const ROUTES = {
  orders: "/",
  collection: "/collection",
  history: "/history",
  stockManager: "/stockmanager",
  stockManagerHotFood: "/stockmanager/hotfood",
  stockManagerChilledDrinks: "/stockmanager/chilleddrinks",
  stockManagerStore: "/stockmanager/store",
  stockManagerDrinkIngredients: "/stockmanager/drinkIngredients",
} as const;

export type RouteKind = keyof typeof ROUTES;
