export function pauseAndResetAudio(audio: HTMLAudioElement) {
  audio.pause();
  audio.setAttribute("currentTime", "0");
}

export async function playOrPauseAudio(
  sound: HTMLAudioElement,
  shouldPlay: boolean
) {
  if (shouldPlay) {
    await sound.play();
  } else {
    pauseAndResetAudio(sound);
  }
}
