import React, { useCallback } from "react";
import { useReduxState, useActions } from "re-reduced";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

import unboundActions from "domain/orders/actions";
import * as selectors from "domain/selectors";

import OrderPickUpCard from "ui/compounds/OrderPickUpCard";
import EmptyState from "ui/components/EmptyState";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      overflow: "auto",
    },
    gridRoot: {
      flexWrap: "wrap",
    },
    tile: {
      margin: theme.spacing(1),
    },
  })
);

const stateSelectors = {
  orders: selectors.getOrdersReadyForCollection,
  selectedId: selectors.getSelectedOrderId,
};

const ReadyForCollection: React.FC = () => {
  const classes = useStyles();
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectors);

  const handleOrderSelection = useCallback(
    (orderId: string | null) => {
      actions.setSelectedId(orderId);
    },
    [actions]
  );

  return (
    <div className={classes.root}>
      {!state.orders.length ? (
        <EmptyState>No orders ready for collection</EmptyState>
      ) : (
        <div className={classes.gridRoot}>
          <GridList cols={Math.min(state.orders.length, 3)} cellHeight={140}>
            {state.orders.map((order) => (
              <GridListTile key={order.id} cols={1}>
                <div className={classes.tile}>
                  <OrderPickUpCard
                    order={order}
                    onClick={handleOrderSelection}
                    selected={state.selectedId === order.id}
                  />
                </div>
              </GridListTile>
            ))}
          </GridList>
        </div>
      )}
    </div>
  );
};

ReadyForCollection.displayName = "ReadyForCollection";

export default ReadyForCollection;
