import React from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";

import { ROUTES } from "domain/core/configuration";
import { RouteComponentProps, withRouter, Switch, Route } from "react-router";
import HotFood from "./HotFood";
import ChilledDrinks from "./ChilledDrinks";
import Store from "./Store";
import { getConvenienceStoreRemoteConfig } from "domain/selectors";
import { useReduxState } from "re-reduced";
import DrinkIngredients from "./DrinkIngredients";

type RouteIndex = 0 | 1 | 2; //TODO: add 3 index when enabling convenience store

const ROUTE_TO_INDEX: Record<string, RouteIndex> = {
  [ROUTES.stockManager]: 0,
  [ROUTES.stockManagerHotFood]: 0,
  [ROUTES.stockManagerChilledDrinks]: 1,
  [ROUTES.stockManagerDrinkIngredients]: 2,
  // [ROUTES.stockManagerStore]: 3,
};

const INDEX_TO_ROUTE: Record<RouteIndex, string> = {
  0: ROUTES.stockManagerHotFood,
  1: ROUTES.stockManagerChilledDrinks,
  2: ROUTES.stockManagerDrinkIngredients,
  // 3: ROUTES.stockManagerStore,
};

const getTabIndexFromRoutePath = (path: string) =>
  path in ROUTE_TO_INDEX ? ROUTE_TO_INDEX[path] : 0;

const stateSelectorMap = {
  convenienceStoreEnabled: getConvenienceStoreRemoteConfig,
};

export function StockManager(props: RouteComponentProps) {
  const tabIndex = getTabIndexFromRoutePath(props.location.pathname);
  const { convenienceStoreEnabled } = useReduxState(stateSelectorMap);

  const handleTabChange = (_: any, index: RouteIndex) => {
    if (index !== tabIndex && index in INDEX_TO_ROUTE) {
      props.history.push(INDEX_TO_ROUTE[index as RouteIndex]);
    }
  };

  return (
    <>
      <AppBar position="sticky" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="FOOD" />
          <Tab label="DRINKS" />
          <Tab label="DRINK INGREDIENTS" />
          {convenienceStoreEnabled && <Tab label="STORE" />}
        </Tabs>
      </AppBar>
      <Switch>
        <Route
          path={ROUTES.stockManagerChilledDrinks}
          component={ChilledDrinks}
        />
        {convenienceStoreEnabled && (
          <Route path={ROUTES.stockManagerStore} component={Store} />
        )}
        <Route
          path={"/stockmanager/drinkIngredients"}
          component={DrinkIngredients}
        />
        <Route path={ROUTES.stockManager} component={HotFood} />
      </Switch>
    </>
  );
}

export default withRouter(StockManager);
