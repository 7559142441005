import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  font-family: monospace;
  font-size: 16px;
  background-color: #e2ecff;
  border-radius: 12px;
  border-top-left-radius: 0;
  padding: 16px;
  margin: 16px;
  min-height: 80px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #333;
`;

const Triangle = styled.div`
  position: absolute;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #e2ecff;
`;

const NoteBalloon: React.FC = (props) => {
  return (
    <Wrapper>
      <Triangle />
      {props.children}
    </Wrapper>
  );
};

NoteBalloon.displayName = "NoteBalloon";

export default NoteBalloon;
