import React, { useState, ChangeEvent, FormEvent, useCallback } from "react";
import { useActions, useReduxState } from "re-reduced";
import assoc from "ramda/src/assoc";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import blue from "@material-ui/core/colors/blue";

import unboundActions from "domain/core/auth/actions";
import { getLoginRequest } from "domain/selectors";
import { APIErrorResponse } from "lib/apiClient/createClient";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: blue[400],
    },
  },
  wrapper: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  loaderWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function getErrorMessage(error: APIErrorResponse) {
  switch (error.status) {
    case 401:
      return "Invalid username or password.";
    default:
      return "An unknown error ocurred while trying to sign in.";
  }
}

const stateSelectors = {
  loginRequest: getLoginRequest,
};

const SignIn: React.FC = () => {
  const classes = useStyles();
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectors);

  const [fields, setFields] = useState({
    email: "",
    password: "",
  });

  const isSingingIn = state.loginRequest.status === "Pending";

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      actions.login({ email: fields.email, password: fields.password });
    },
    [actions, fields.email, fields.password]
  );

  const handleFieldChange = useCallback(
    (field: "email" | "password") => (e: ChangeEvent<HTMLInputElement>) =>
      setFields(assoc(field, e.target.value)),
    []
  );

  const handleEmailChange = handleFieldChange("email");
  const handlePasswordChange = handleFieldChange("password");

  const hasLoginError = state.loginRequest.status === "Failed";

  return (
    <Container component="main" maxWidth="xs" className={classes.wrapper}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon titleAccess="sign-in icon" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleEmailChange}
            error={hasLoginError}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handlePasswordChange}
            error={hasLoginError}
          />
          <div className={classes.loaderWrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSingingIn}
            >
              {isSingingIn ? "…" : "Sign In"}
            </Button>
            {isSingingIn && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          {state.loginRequest.error !== undefined && (
            <Typography color="error" align="center">
              {getErrorMessage(state.loginRequest.error)}
            </Typography>
          )}
        </form>
      </div>
    </Container>
  );
};

SignIn.displayName = "SignIn";

export default SignIn;
