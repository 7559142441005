import React from "react";
import styled from "styled-components";
import { CircularProgress, FormControlLabel } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { ProductId } from "domain/core/types";
import {
  DisabledCustomisationItem,
  DisabledItem,
  StockManagerItem,
} from "domain/core/app/types";
import ConfirmDialog from "ui/components/ConfirmDialog";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

export const SectionContainer = styled.div`
  position: relative;
  width: 300px;
  margin-right: 20px;
`;

export const SectionList = styled.div`
  overflow: scroll;
  max-height: 618px;
  padding-bottom: 65px;
`;

export const SectionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${grey[300]};
`;

export const SectionHeader = styled.div`
  flex: 1;
  font-size: 20px;
`;

export const OptionContainer = styled(FormControlLabel)`
  width: 100%;
  background-color: ${grey[200]};
  border-radius: 5px;
  height: 50px;
  margin-top: 10px;
  display: flex;
  justify-items: center;
  padding-left: 10px;
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

export const LoadingOverlayContainer = styled.div`
  background-color: white;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

interface LoadingOverlayProps {
  isLoading: boolean;
}
export const LoadingOverlay = (props: LoadingOverlayProps) => {
  if (props.isLoading) {
    return (
      <LoadingOverlayContainer>
        <CircularProgress />
      </LoadingOverlayContainer>
    );
  }

  return null;
};

export const isProductDisabled = (
  productId: ProductId,
  disabledItems: DisabledItem[]
) => disabledItems.some((item) => item.productId === productId);

export const handleToggleChange = (
  item: StockManagerItem,
  isLoading: boolean,
  setActionPendingConfirmation: (
    actionPendingConfirmation: ActionPendingConfirmation
  ) => void
) => (event: React.ChangeEvent<HTMLInputElement>) => {
  if (isLoading) return;

  const disableOrEnable = event.target.checked ? "enable" : "disable";

  setActionPendingConfirmation({ item, disableOrEnable });
};

export const handleCustomisationToggleChange = (
  item: StockManagerItem,
  isLoading: boolean,
  setActionPendingConfirmation: (
    actionPendingConfirmation: ActionPendingConfirmation
  ) => void
) => (event: React.ChangeEvent<HTMLInputElement>) => {
  if (isLoading) return;

  const disableOrEnable = event.target.checked ? "enable" : "disable";

  setActionPendingConfirmation({
    item,
    disableOrEnable,
    isForCustomisation: true,
  });
};

export const handleStockStatusToggleChange = (
  item: StockManagerItem,
  isLoading: boolean,
  setActionPendingConfirmation: (
    actionPendingConfirmation: ActionPendingConfirmation
  ) => void
) => (event: React.ChangeEvent<HTMLInputElement>) => {
  if (isLoading) return;

  const disableOrEnable = event.target.checked ? "enable" : "disable";

  setActionPendingConfirmation({ item, disableOrEnable, isForStock: true });
};

export interface ActionPendingConfirmation {
  item: StockManagerItem;
  disableOrEnable: "enable" | "disable";
  isForStock?: boolean;
  isForCustomisation?: boolean;
}

interface ModalProps {
  actionPendingConfirmation?: ActionPendingConfirmation;
  onClose: () => void;
  onConfirm: () => void;
}
export const ConfirmChangeStockAvailabilityModal = (props: ModalProps) => {
  const willDisable =
    props.actionPendingConfirmation?.disableOrEnable === "disable";

  return (
    <ConfirmDialog
      open={Boolean(props.actionPendingConfirmation)}
      title={`${willDisable ? "Disable" : "Enable"} ${
        props.actionPendingConfirmation?.item.label
      }?`}
      cancelLabel="CANCEL"
      confirmLabel={"CONFIRM"}
      onClose={props.onClose}
      onCancel={props.onClose}
      onConfirm={props.onConfirm}
    >
      This will {props.actionPendingConfirmation?.disableOrEnable} customers{" "}
      {willDisable ? "from " : "to "}
      order{willDisable && "ing"} {props.actionPendingConfirmation?.item.label}{" "}
      via Z App.
    </ConfirmDialog>
  );
};

export const isCustomisationItemDisabled = (
  productId: ProductId,
  disabledCustomisationItems: DisabledCustomisationItem[]
) =>
  disabledCustomisationItems.some(
    (item) => item.productId === productId && item.isTemporary
  );

export const isCustomisationItemOutOfStock = (
  productId: ProductId,
  disabledCustomisationItems: DisabledCustomisationItem[]
) =>
  disabledCustomisationItems.some(
    (item) => item.productId === productId && !item.isTemporary
  );

export const isNoneCustomisationItemStock = (
  disabledCustomisationItems: DisabledCustomisationItem[],
  items: StockManagerItem[]
) =>
  !items.some(
    (customisationItem) =>
      !isCustomisationItemOutOfStock(
        customisationItem.productId,
        disabledCustomisationItems
      )
  );
