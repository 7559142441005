import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { useReduxState, useActions } from "re-reduced";
import { useSnackbar } from "notistack";
import last from "ramda/src/last";

import Button from "@material-ui/core/Button";

import { getAppSnacks } from "domain/core/app/selectors";
import unboundActions from "domain/core/app/actions";
import CollapsableSnack from "ui/components/CollapsableSnack";

const stateSelectors = {
  snacks: getAppSnacks,
};

export const Snackbar: React.FC = () => {
  const dispatch = useDispatch();
  const actions = useActions(unboundActions);
  const snackbar = useSnackbar();

  const state = useReduxState(stateSelectors);

  useEffect(() => {
    return () => {
      // Clean up any persited snackbars
      snackbar.closeSnackbar();
    };
  }, [snackbar]);

  useEffect(() => {
    const lastSnack = last(state.snacks);

    if (!lastSnack) {
      return;
    }

    if (lastSnack.clearPersistedSnacks) {
      snackbar.closeSnackbar();
    }

    const handleClick = () => {
      if (lastSnack.action) {
        dispatch(lastSnack.action.dispatchableAction);
      }
    };

    const handleSnackbarEnter = () => {
      if (lastSnack.id) {
        actions.dismissSnackMessage(lastSnack.id);
      }
    };

    const baseSnackbarProps = {
      id: lastSnack.id,
      variant: lastSnack.variant,
      onEnter: handleSnackbarEnter,
      autoHideDuration: lastSnack.duration ?? 2500,
    };

    if (lastSnack.collapsedMessage) {
      const content = (key: string) => (
        <CollapsableSnack {...lastSnack} id={key} />
      );

      snackbar.enqueueSnackbar("", {
        ...baseSnackbarProps,
        persist: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        content,
      });
    } else {
      snackbar.enqueueSnackbar(lastSnack.message, {
        ...baseSnackbarProps,
        key: lastSnack.id,
        action: lastSnack.action && (
          <Button color="inherit" onClick={handleClick}>
            {lastSnack.action.label}
          </Button>
        ),
        persist: lastSnack.persist,
      });
    }
  }, [state.snacks, actions, dispatch, snackbar]);

  return null;
};

Snackbar.displayName = "Snackbar";

export default withRouter(Snackbar);
