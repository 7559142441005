import React, { useState } from "react";
import { useActions, useReduxState } from "re-reduced";

import StyledSwitch from "ui/components/StyledSwitch";
import actions from "domain/core/app/actions";
import {
  getDisabledCustomisationItems,
  getDisabledItems,
  getDisabledItemsRequestState,
} from "domain/selectors";
import { REQUEST_STATUS } from "lib/types";

import {
  LoadingOverlay,
  Container,
  SectionContainer,
  SectionHeaderContainer,
  SectionHeader,
  OptionContainer,
  isProductDisabled,
  handleToggleChange,
  ActionPendingConfirmation,
  ConfirmChangeStockAvailabilityModal,
  isCustomisationItemDisabled,
  isCustomisationItemOutOfStock,
  handleCustomisationToggleChange,
  SectionList,
} from "./Shared";
import { ESPRESS_PIE_ENABLED } from "domain/orders/configuration";
import { HotFoodItems, MuffinItems } from "domain/core/app/stockConstants";

const unboundActions = {
  updateProductAvailability: actions.updateProductAvailability,
  updateCustomisationAvailability: actions.updateCustomisationAvailability,
};

const stateSelectorMap = {
  disabledItems: getDisabledItems,
  disabledItemsRequestState: getDisabledItemsRequestState,
  disabledCustomisationItems: getDisabledCustomisationItems,
};

export default function HotFood() {
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectorMap);

  const isLoading = state.disabledItemsRequestState === REQUEST_STATUS.Pending;

  const [actionPendingConfirmation, setActionPendingConfirmation] = useState<
    ActionPendingConfirmation
  >();

  const handleConfirmDialogClose = () => {
    setActionPendingConfirmation(undefined);
  };

  const handleConfirmDialogConfirm = () => {
    if (actionPendingConfirmation) {
      if (actionPendingConfirmation.isForCustomisation) {
        actions.updateCustomisationAvailability({
          productId: actionPendingConfirmation.item.productId,
          disableOrEnable: actionPendingConfirmation.disableOrEnable,
          isTemporary: actionPendingConfirmation.disableOrEnable === "disable",
        });
      } else {
        actions.updateProductAvailability({
          productIds: [actionPendingConfirmation.item.productId],
          disableOrEnable: actionPendingConfirmation.disableOrEnable,
        });
      }

      handleConfirmDialogClose();
    }
  };

  return (
    <Container>
      <LoadingOverlay isLoading={isLoading} />
      {!!ESPRESS_PIE_ENABLED && (
        <SectionContainer>
          <SectionHeaderContainer>
            <SectionHeader>Classic Pies</SectionHeader>
          </SectionHeaderContainer>
          <SectionList>
            {HotFoodItems.map((item) => (
              <OptionContainer
                key={item.productId}
                control={
                  <StyledSwitch
                    onChange={handleToggleChange(
                      item,
                      isLoading,
                      setActionPendingConfirmation
                    )}
                    checked={
                      !isProductDisabled(item.productId, state.disabledItems)
                    }
                  />
                }
                label={item.label}
              />
            ))}
          </SectionList>
        </SectionContainer>
      )}
      <SectionContainer>
        <SectionHeaderContainer>
          <SectionHeader>Muffin Availability</SectionHeader>
        </SectionHeaderContainer>
        <SectionList>
          {MuffinItems.map(
            (item) =>
              !isCustomisationItemOutOfStock(
                item.productId,
                state.disabledCustomisationItems
              ) && (
                <OptionContainer
                  key={item.productId}
                  control={
                    <StyledSwitch
                      onChange={handleCustomisationToggleChange(
                        item,
                        isLoading,
                        setActionPendingConfirmation
                      )}
                      checked={
                        !isCustomisationItemDisabled(
                          item.productId,
                          state.disabledCustomisationItems
                        )
                      }
                    />
                  }
                  label={item.label}
                />
              )
          )}
        </SectionList>
      </SectionContainer>

      <ConfirmChangeStockAvailabilityModal
        actionPendingConfirmation={actionPendingConfirmation}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDialogConfirm}
      />
    </Container>
  );
}
