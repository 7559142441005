import React, { useState, useMemo, useCallback } from "react";
import { useActions, useReduxState } from "re-reduced";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import UpdateIcon from "@material-ui/icons/NotificationImportant";
import blueGrey from "@material-ui/core/colors/blueGrey";

import { APP_NAME } from "domain/core/app/configuration";
import unboundActions from "domain/core/actions";
import * as selectors from "domain/core/selectors";

import ConfirmDialog from "ui/components/ConfirmDialog";
import LeftMenu from "ui/compounds/LeftMenu";
import StyledSwitch from "ui/components/StyledSwitch";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      zIndex: 1,
      background: blueGrey[700],
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
    subtitle: {
      marginLeft: theme.spacing(1),
    },
    badgeIcon: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
    },
  })
);

const stateSelectors = {
  isOnline: selectors.getIsSiteOnline,
  siteName: selectors.getSiteName,
  hasUpdates: selectors.getHasUpdates,
};

const MainAppBar: React.FC = () => {
  const classes = useStyles();
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectors);
  const [showToggleSiteDialog, setShowToggleSiteDialog] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const handleShowToggleSiteDialog = useCallback(
    () => setShowToggleSiteDialog(true),
    []
  );

  const handleCloseToggleSiteDialog = useCallback(
    () => setShowToggleSiteDialog(false),
    []
  );

  const handleShowLeftMenu = useCallback(() => setShowDrawer(true), []);

  const handleCloseLeftMenu = useCallback(() => setShowDrawer(false), []);

  const handleConfirmToggleSite = useCallback(() => {
    actions.app.updatePreorderStatus({ online: !state.isOnline });
    setShowToggleSiteDialog(false);
  }, [actions.app, state.isOnline]);

  const statusLabel = useMemo(() => (state.isOnline ? "disable" : "enable"), [
    state.isOnline,
  ]);

  return (
    <>
      <AppBar position="static" className={classes.wrapper}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={handleShowLeftMenu}
          >
            <Badge
              color="secondary"
              badgeContent={<UpdateIcon className={classes.badgeIcon} />}
              invisible={!state.hasUpdates}
            >
              <MenuIcon />
            </Badge>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {APP_NAME}
            <Typography component="span" className={classes.subtitle}>
              | {state.siteName}
            </Typography>
          </Typography>
          <FormControlLabel
            onClick={handleShowToggleSiteDialog}
            control={<StyledSwitch checked={state.isOnline} />}
            label={`${state.isOnline ? "Enabled" : "Disabled"}`}
            labelPlacement="start"
          />
        </Toolbar>
      </AppBar>
      <ConfirmDialog
        open={showToggleSiteDialog}
        onClose={handleCloseToggleSiteDialog}
        onCancel={handleCloseToggleSiteDialog}
        onConfirm={handleConfirmToggleSite}
        title={`Are you sure you want to ${statusLabel} pre-order on this site?`}
        cancelLabel="Cancel"
        confirmLabel="Confirm"
      >
        A notification email will be sent to the site administrator.
      </ConfirmDialog>
      <LeftMenu
        siteName={state.siteName}
        open={showDrawer}
        onClose={handleCloseLeftMenu}
      />
    </>
  );
};

MainAppBar.displayName = "MainAppBar";

export default MainAppBar;
