import { createSelector } from "reselect";
import decodeJWT from "jwt-decode";
import parse from "date-fns/parse";
import isBefore from "date-fns/isBefore";
import addDays from "date-fns/addDays";

import { getNow } from "lib/date-time";

import { BaristaState } from "domain/types";
import { decodeToken, decodeJWTData } from "domain/core/auth/helpers";

export const getAuthState = (state: BaristaState) => state.core.auth;

export const getToken = createSelector(getAuthState, (state) =>
  decodeToken(state.token ?? "")
);

export const getAuthTokenExpiry = createSelector(getToken, (token) => {
  try {
    const decoded = decodeJWT<{ exp: number }>(token);
    return decoded.exp;
  } catch (error) {
    return 0;
  }
});

export const getIsTokenExpiryWithinNDays = (numberOfDays: number) =>
  createSelector(getAuthTokenExpiry, getNow, (tokenExpiry, now) => {
    const expDate = parse(String(tokenExpiry), "t", new Date());
    const adjustedDate = addDays(now, numberOfDays);

    return isBefore(adjustedDate, expDate);
  });

export const getIsTokenExpiryWithin7Days = getIsTokenExpiryWithinNDays(7);

export const getRefreshToken = createSelector(getAuthState, (state) =>
  decodeToken(state.refreshToken ?? "")
);

export const getIsAuthenticated = createSelector(getToken, Boolean);

export const getSiteName = createSelector(
  getAuthState,
  (state) => state.siteName
);

export const getDecodedJWTData = createSelector(getToken, decodeJWTData);

export const getSiteId = createSelector(getDecodedJWTData, (data) =>
  data ? data.siteid : undefined
);

export const getLoginRequest = createSelector(
  getAuthState,
  (state) => state.requests.login
);

export const getAppVersion = () => global.appVersion;
