import { createActions } from "re-reduced";

import { Result } from "lib/types";
import {
  OrdersQueueResult,
  OrdersResult,
  PatchOrderRequest,
  PatchOrderResult,
  OrderStatus,
} from "./types";

export default createActions("ORDERS", (create) => ({
  setSelectedId: create.action<string | null>(),
  pollQueue: create.asyncAction(),
  fetchQueued: create.asyncAction<OrdersQueueResult>(),
  fetchAcknowledged: create.asyncAction<OrdersResult>(),
  refresh: create.asyncAction<OrdersResult, OrderStatus[] | void>(),
  acknowledgeQueue: create.asyncAction<Result<string[]>>(),
  updateStatus: create.asyncAction<PatchOrderResult, PatchOrderRequest>(),
}));
