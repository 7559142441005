import axios from "axios";

import createUUID from "lib/uuid";

import createClient from "./createClient";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: Number(process.env.REACT_APP_API_TIMEOUT ?? 10000),
});

/**
 * Sets token to client's Authorization header
 *
 * @param {string} token - api bearer token
 */
export function setToken(token: string) {
  client.defaults.headers.Authorization = `Bearer ${token}`;
}

export default createClient(client, {
  includeRequestId: {
    headerName: "x-correlation-id",
    uuIdFn: createUUID,
  },
});
