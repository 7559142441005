import React from "react";
import styled from "styled-components";

import CircularProgress from "@material-ui/core/CircularProgress";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
  margin: 12px;
`;

const Loading: React.FC = () => (
  <Wrapper>
    <CircularProgress />
  </Wrapper>
);

Loading.displayName = "Loading";

export default Loading;
