import React, { useState, useCallback, useMemo } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import CircularProgress from "@material-ui/core/CircularProgress";

import {
  ConfirmMessage,
  OrderStatus,
  OrderActionConfig,
  Order,
  PatchOrderRequest,
} from "domain/orders/types";

import { STATUS_TRANSITIONS, ORDER_ACTIONS } from "domain/orders/configuration";
import ConfirmDialog from "ui/components/ConfirmDialog";

const useStyles = makeStyles(() =>
  createStyles({
    actions: {
      justifyContent: "space-between",
    },
    buttonWrapper: {
      position: "relative",
      flex: 1,
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -22,
      marginLeft: -22,
    },
  })
);

interface Props {
  order: Order;
  isTransitioning: boolean;
  onUpdateStatus(payload: PatchOrderRequest): void;
}

const OrderActions: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [confirmMessage, setConfirmMessage] = useState<
    ConfirmMessage | undefined
  >(undefined);

  const handleStatusTransition = useCallback(
    (status: OrderStatus, config?: OrderActionConfig) => () => {
      if (!props.order) {
        return;
      }

      if (config?.confirm) {
        setConfirmMessage(config.confirm);
      } else {
        props.onUpdateStatus({ orderId: props.order.id, status });
        setConfirmMessage(undefined);
      }
    },
    [props]
  );

  const legalTransitions = STATUS_TRANSITIONS[props.order.status];

  const actionButtons = useMemo(
    () =>
      legalTransitions
        .map((status) => ({ status, config: ORDER_ACTIONS[status] }))
        .filter((data) =>
          data.config.shouldDisplay && props.order
            ? data.config.shouldDisplay(props.order)
            : true
        ),
    [props.order, legalTransitions]
  );

  const handleClose = useCallback(() => setConfirmMessage(undefined), []);

  if (!legalTransitions.length) {
    return null;
  }

  return (
    <>
      <div className={classes.actions}>
        <Divider />
        <div className={classes.buttonWrapper}>
          <List>
            {actionButtons.map((data) => (
              <ListItem key={data.status}>
                <Button
                  fullWidth
                  variant={data.config.variant}
                  color={data.config.color ?? "primary"}
                  onClick={handleStatusTransition(data.status, data.config)}
                  disabled={props.isTransitioning}
                >
                  {data.config.label}
                </Button>
              </ListItem>
            ))}
          </List>
          {props.isTransitioning && (
            <CircularProgress size={44} className={classes.buttonProgress} />
          )}
        </div>
      </div>
      <ConfirmDialog
        open={!!confirmMessage}
        title={confirmMessage ? confirmMessage.title : ""}
        cancelLabel={confirmMessage ? confirmMessage.actions.cancel.label : ""}
        confirmLabel={
          confirmMessage ? confirmMessage.actions.confirm.label : ""
        }
        onClose={handleClose}
        onCancel={handleClose}
        onConfirm={handleStatusTransition("Cancelled")}
      >
        {confirmMessage ? confirmMessage.body : ""}
      </ConfirmDialog>
    </>
  );
};

OrderActions.displayName = "OrderActions";

export default OrderActions;
