import { Middleware } from "redux";
import { Action } from "re-reduced";
import actions from "domain/core/app/actions";
import { browserHistory } from "lib/browser";
import { ROUTES, RouteKind } from "domain/core/configuration";

const historyMiddleware: Middleware = () => (next) => (
  action: Action<RouteKind>
) => {
  if (action.type === actions.navigate.type) {
    const route = ROUTES[action.payload];
    browserHistory.push(route);
  }
  return next(action);
};

export default historyMiddleware;
