import React, { useState } from "react";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorIcon from "@material-ui/icons/Error";

import { SnackbarItem } from "domain/core/app/types";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400,
    minWidth: 344,
  },
  typography: {},
  actionRoot: {
    padding: "8px 8px 8px 16px",
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
  icons: {
    marginLeft: "auto",
  },
  expand: {
    padding: "8px 8px",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.error.contrastText,
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapse: {
    padding: 16,
    overflowX: "scroll",
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: "none",
  },
}));

interface Props extends SnackbarItem {
  id: string;
}

const CollapsableSnack = React.forwardRef<typeof Card, Props>((props, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDismiss = () => {
    closeSnackbar(props.id);
  };

  return (
    <Card className={classes.card} ref={ref}>
      <CardActions classes={{ root: classes.actionRoot }}>
        <ErrorIcon className={classes.checkIcon} />
        <Typography variant="caption" className={classes.typography}>
          {props.message}
        </Typography>
        <div className={classes.icons}>
          <IconButton
            aria-label="Show more"
            className={classnames(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
          <IconButton className={classes.expand} onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </div>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Paper className={classes.collapse}>
          <pre>{props.collapsedMessage}</pre>
        </Paper>
      </Collapse>
    </Card>
  );
});

CollapsableSnack.displayName = "CollapsableSnack";

export default CollapsableSnack;
