import { SagaIterator } from "redux-saga";
import {
  takeLatest,
  take,
  race,
  all,
  put,
  call,
  select,
  delay,
} from "redux-saga/effects";
import { apiWorkerFactory, Action } from "re-reduced";

import { Result } from "lib/types";
import { getISOTimeStamp } from "lib/date-time";

import {
  getRouteFromStatus,
  isTransitioningToSameStatus,
} from "domain/orders/helpers";
import { ORDERS_FALLBACK_POLL_INTERVAL } from "domain/orders/configuration";
import {
  PatchOrderRequest,
  OrderStatus,
  PatchOrderResult,
  Order,
} from "domain/orders/types";

import * as api from "domain/orders/api";
import * as selectors from "domain/selectors";
import appActions from "domain/core/app/actions";
import authActions from "domain/core/auth/actions";
import ordersActions from "domain/orders/actions";
import { TabRouteName } from "domain/core/app/types";
import { APIErrorResponse } from "lib/apiClient/createClient";

export default function* AuthSagaWatcher(): SagaIterator {
  yield all([
    takeLatest(ordersActions.pollQueue.type, pollQueueWatcher),
    takeLatest(ordersActions.fetchQueued.type, fetchQueued),
    takeLatest(ordersActions.acknowledgeQueue.type, acknowledgeQueue),
    takeLatest(ordersActions.updateStatus.type, updateStatus),
    takeLatest(
      [
        authActions.login.success.type,
        ordersActions.refresh.type,
        appActions.restored.type,
      ],
      refreshOrders
    ),
  ]);
}

/**
 * An effect that returns true if the browser tab is active
 */
export function getIsBrowserTabActive(): Promise<boolean> {
  return new Promise((resolve) => {
    try {
      requestAnimationFrame(() => resolve(true));
    } catch (error) {
      resolve(false);
    }
  });
}

export function* pollQueueWorker() {
  const isOnline: boolean = yield select(selectors.getIsSiteOnline);

  if (!isOnline) {
    return;
  }

  const isBrowserTabActive: boolean = yield call(getIsBrowserTabActive);

  if (!isBrowserTabActive) {
    return;
  }

  yield call(fetchQueued, ordersActions.fetchQueued());
  yield delay(ORDERS_FALLBACK_POLL_INTERVAL);
  yield put(ordersActions.pollQueue());
}

export function* pollQueueWatcher() {
  yield race([
    call(pollQueueWorker),
    take(ordersActions.pollQueue.cancel.type),
  ]);
}

export function* acknowledgeQueue(): SagaIterator {
  const queuedIds: string[] = yield select(selectors.getQueuedIds);

  // should terminate when there are no orders in queue
  if (!queuedIds.length) {
    return;
  }

  yield put(ordersActions.acknowledgeQueue.request());

  try {
    const acknowledgedResponse: Result<string[]> = yield call(api.patchMany, {
      orderIds: queuedIds,
      currentStatus: "Paid",
      nextStatus: "Acknowledged",
    });

    // should only call fetch acknowledged if there are acknowledged ids
    if (acknowledgedResponse.items.length) {
      yield call(fetchAcknowledged, ordersActions.fetchAcknowledged());
    }

    yield all([
      put(ordersActions.acknowledgeQueue.success(acknowledgedResponse)),
      put(
        appActions.logEvent({
          kind: "Info",
          message: "Orders acknowledged",
        })
      ),
    ]);

    const tabRoute: TabRouteName = yield select(selectors.getCurrentTabRoute);
    if (tabRoute !== "/") {
      yield put(ordersActions.setSelectedId(null));
    }
    yield put(appActions.navigate("orders"));
  } catch (error) {
    yield put(ordersActions.acknowledgeQueue.failure(error as Error));
  }
}

export const fetchQueued = apiWorkerFactory(
  ordersActions.fetchQueued,
  api.fetchQueued,
  {
    *onFailure(error) {
      yield all([
        put(ordersActions.fetchQueued.failure(error)),
        put(
          appActions.enqueueSnackbar({
            variant: "error",
            message: "Failed to refresh queue",
          })
        ),
      ]);
    },
  }
);

export const fetchAcknowledged = apiWorkerFactory(
  ordersActions.fetchAcknowledged,
  api.fetchAcknowledged,
  {
    *onFailure(error) {
      yield all([
        put(ordersActions.fetchAcknowledged.failure(error)),
        put(
          appActions.enqueueSnackbar({
            variant: "error",
            message: "Failed to fetch orders",
          })
        ),
      ]);
    },
  }
);

export function* updateStatus(action: Action<PatchOrderRequest>): SagaIterator {
  yield put(ordersActions.updateStatus.request());

  const now: string = yield select(getISOTimeStamp);

  const result: PatchOrderResult = {
    id: action.payload.orderId,
    status: action.payload.status,
    statusUpdatedAt: now,
  };

  try {
    yield call(api.patchSingle, action.payload);

    yield put(ordersActions.updateStatus.success(result));

    const route = getRouteFromStatus(action.payload.status);

    yield put(
      appActions.enqueueSnackbar({
        variant: "success",
        message: `Order status updated`,
        action: {
          label: "View tab",
          dispatchableAction: appActions.navigate(route),
        },
        duration: 3000,
      })
    );

    yield put(ordersActions.setSelectedId(null));
  } catch (error) {
    if (isTransitioningToSameStatus((error as APIErrorResponse).data)) {
      yield put(ordersActions.updateStatus.success(result));
      return;
    }

    yield all([
      put(ordersActions.updateStatus.failure(error as Error)),
      put(
        appActions.enqueueSnackbar({
          variant: "error",
          message: `Failed to update order status`,
          action: {
            label: "Retry",
            dispatchableAction: action,
          },
          duration: 5000,
        })
      ),
    ]);
  }
}

export function* refreshOrders(): SagaIterator {
  const token = yield select(selectors.getToken);

  if (!token) {
    return;
  }

  yield put(ordersActions.refresh.request());

  try {
    const statuses: OrderStatus[] = [
      "Acknowledged",
      "Made",
      "PickedUp",
      "Late",
      "NotPickedUp",
      "Cancelled",
    ];
    const result: Result<Order[]> = yield call(api.fetchAll, statuses);

    yield put(ordersActions.refresh.success(result));
  } catch (error) {
    yield put(ordersActions.refresh.failure(error as Error));
  }
}
