import React, { useState, useEffect, useCallback } from "react";
import { useReduxState, useActions } from "re-reduced";
import red from "@material-ui/core/colors/red";

import { getIsSiteOnline } from "domain/core/app/selectors";
import unboundActions from "domain/core/app/actions";

import FullScreenAlert, {
  Circle,
  Headline,
  SubText,
} from "ui/components/FullScreenAlert";

const stateSelectors = {
  isOnline: getIsSiteOnline,
};

const SiteOfflineAlert: React.FC = () => {
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectors);

  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    if (!state.isOnline) {
      setDismissed(false);
    }
  }, [state.isOnline]);

  const handleConfirm = useCallback(() => {
    setDismissed(true);
    actions.enqueueSnackbar({
      message: "Site is still disabled.",
      variant: "error",
      persist: true,
      clearPersistedSnacks: true,
    });
  }, [actions]);

  if (state.isOnline || dismissed) {
    return null;
  }

  return (
    <FullScreenAlert onClick={handleConfirm} color={red[700]}>
      <Circle color={red[600]}>!</Circle>
      <Headline>Site is disabled</Headline>
      <SubText>Tap anywhere to dismiss</SubText>
    </FullScreenAlert>
  );
};

SiteOfflineAlert.displayName = "SiteOfflineAlert";

export default SiteOfflineAlert;
