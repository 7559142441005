import styled, { keyframes } from "styled-components";
import red from "@material-ui/core/colors/red";

import propOr from "ramda/src/propOr";

export const pulse = keyframes`
  0% {
    transform: scale(.9)
  }
  50% {
    transform: scale(1)
  }
  100% {
    transform: scale(.9)
  }
`;

const appear = keyframes`
  0% {
    opacity: 0.1
  }
  100% {
    opacity: 1
  }
`;

export const Appear = styled.div`
  animation: ${appear} 0.5s ease-in;
`;

interface CircleProps {
  color?: string;
  still?: boolean;
}

export const Circle = styled.div<CircleProps>`
  height: 50vh;
  width: 50vh;
  border-radius: 50%;
  font-size: 30vh;
  color: white;
  background-color: ${propOr(red[600], "color")};
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${pulse} ${(props) => (props.still ? "0" : "2s")} infinite;
`;

export const Headline = styled.div`
  text-align: center;
  color: whitesmoke;
  font-weight: bold;
  font-size: 40px;
  margin: 12px;
`;

export const SubText = styled.div`
  text-align: center;
  color: whitesmoke;
  font-size: 25px;
`;

interface WrapperProps {
  color?: string;
}

export const FullScreenAlert = styled.div<WrapperProps>`
  z-index: 1310; // to render on top of badges and drawer overlays
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: ${propOr(red[700], "color")};
  animation: ${appear} 1s ease-in-out;
`;

FullScreenAlert.displayName = "FullScreenAlert";

export default FullScreenAlert;
