import React, { useCallback } from "react";
import styled from "styled-components";
import { useReduxState, useActions } from "re-reduced";

import blue from "@material-ui/core/colors/blue";
import blueGrey from "@material-ui/core/colors/blueGrey";

import ZLogoSrc from "assets/z-icon.png";
import BaristaIllustrationSrc from "assets/barista.png";

import unboundAppActions from "domain/core/app/actions";
import { getIsAlertEnabled } from "domain/core/app/selectors";
import FullScreenAlert, {
  Circle,
  Headline,
  SubText,
  Appear,
} from "ui/components/FullScreenAlert";

const unboundActions = {
  dismiss: unboundAppActions.bootstrap.success,
};

const Icon = styled.img``;

const stateSelectors = {
  isAlertEnabled: getIsAlertEnabled,
};

const Splash: React.FC = () => {
  const state = useReduxState(stateSelectors);
  const actions = useActions(unboundActions);

  const handleClick = useCallback(() => {
    actions.dismiss();
  }, [actions]);

  return (
    <FullScreenAlert
      color={state.isAlertEnabled ? blue[400] : blueGrey[700]}
      onClick={handleClick}
    >
      <Circle color="transparent" still={!state.isAlertEnabled}>
        {state.isAlertEnabled ? (
          <Icon src={ZLogoSrc} alt="z logo" />
        ) : (
          <Appear style={{ height: "40vh", width: "40vh" }}>
            <Icon
              style={{ height: "40vh", width: "40vh" }}
              src={BaristaIllustrationSrc}
              alt="Barista. Icon made by Monkik from www.flaticon.com"
            />
          </Appear>
        )}
      </Circle>
      <Headline>
        {state.isAlertEnabled
          ? "Hang on, Barista is initialising…"
          : "Welcome to Barista"}
      </Headline>
      {!state.isAlertEnabled && <SubText>Tap anywhere to continue</SubText>}
    </FullScreenAlert>
  );
};

Splash.displayName = "Splash";

export default Splash;
