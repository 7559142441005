import React, {
  useEffect,
  ChangeEvent,
  useMemo,
  ReactNode,
  useCallback,
} from "react";
import SwipeableViews, { OnChangeIndexCallback } from "react-swipeable-views";
import { useReduxState, useActions } from "re-reduced";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import grey from "@material-ui/core/colors/grey";

import { ROUTES } from "domain/core/configuration";
import * as selectors from "domain/selectors";
import orderActions from "domain/orders/actions";
import appActions from "domain/core/app/actions";

import OrderDetailsDrawer from "ui/compounds/OrderDetailsDrawer";

import Scheduled from "ui/pages/Scheduled";
import Collection from "ui/pages/ReadyForCollection";
import History from "ui/pages/History";
import { TabRouteName } from "domain/core/app/types";

type RouteIndex = 0 | 1 | 2;

const ROUTE_TO_INDEX: Record<string, RouteIndex> = {
  [ROUTES.orders]: 0,
  [ROUTES.collection]: 1,
  [ROUTES.history]: 2,
};

const INDEX_TO_ROUTE: Record<RouteIndex, string> = {
  0: ROUTES.orders,
  1: ROUTES.collection,
  2: ROUTES.history,
};

const getTabIndexFromRoutePath = (path: string) =>
  path in ROUTE_TO_INDEX ? ROUTE_TO_INDEX[path] : 0;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flex: 1,
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    padding: {
      padding: theme.spacing(0, 2),
    },
    tabsContainer: {
      zIndex: 1,
    },
    tabContainer: {
      padding: 20,
      backgroundColor: grey[50],
      height: "80vh",
      display: "flex",
      justifyContent: "center",
    },
  })
);

const TabContainer: React.FC = (props) => {
  const classes = useStyles();

  return <div className={classes.tabContainer}>{props.children}</div>;
};

const LabelWithBadge: React.FC<{ count?: number; label: string }> = (props) => {
  const classes = useStyles();

  if (props.count) {
    return (
      <Badge
        className={classes.padding}
        color="secondary"
        badgeContent={props.count}
      >
        {props.label}
      </Badge>
    );
  }
  return <>{props.label}</>;
};

const stateSelectorMap = {
  scheduledCount: selectors.getScheduledOrdersCount,
  readyCount: selectors.getReadyOrdersCount,
  isSiteOnline: selectors.getIsSiteOnline,
};

const unboundActions = {
  ...orderActions,
  updateCurrentTabRoute: appActions.updateCurrentTabRoute,
};

export const Orders: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const tabIndex = useMemo(
    () => getTabIndexFromRoutePath(props.location.pathname),
    [props.location.pathname]
  );
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectorMap);

  useEffect(() => {
    actions.pollQueue();

    return () => {
      // cleanup
      actions.pollQueue.cancel();
    };
  }, [actions, state.isSiteOnline]);

  useEffect(() => {
    actions.updateCurrentTabRoute(props.location.pathname as TabRouteName);
  }, [actions, props.location.pathname]);

  const handleIndexChange = useCallback<OnChangeIndexCallback>(
    (index: number, latestIndex: number) => {
      if (index !== latestIndex && index in INDEX_TO_ROUTE) {
        const route = INDEX_TO_ROUTE[index as RouteIndex];
        props.history.push(route);
      }
    },
    [props.history]
  );

  const handleTabChange = useCallback(
    (_: ChangeEvent<ReactNode>, index: RouteIndex) => {
      handleIndexChange(index, tabIndex);
    },
    [handleIndexChange, tabIndex]
  );

  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="sticky"
          color="default"
          className={classes.tabsContainer}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={
                <LabelWithBadge
                  label="New orders"
                  count={state.scheduledCount}
                />
              }
            />
            <Tab
              label={
                <LabelWithBadge
                  label="Ready for Collection"
                  count={state.readyCount}
                />
              }
            />
            <Tab label="History" />
          </Tabs>
        </AppBar>

        <SwipeableViews
          axis="x"
          index={tabIndex}
          onChangeIndex={handleIndexChange}
          enableMouseEvents={true}
        >
          <TabContainer>
            <Scheduled />
          </TabContainer>
          <TabContainer>
            <Collection />
          </TabContainer>
          <TabContainer>
            <History />
          </TabContainer>
        </SwipeableViews>
      </div>
      <OrderDetailsDrawer />
    </>
  );
};

Orders.displayName = "Orders";

export default withRouter(Orders);
